import { useSelector } from 'react-redux';
import { getDefaultStore, useAtom } from 'jotai';
import { i18nLists, List } from '../atoms/i18n-atoms';

import { localeSelector } from '$redux/config/configSelectors';

type TranslateKey = `${List}.${string}`;

const listsBeingFetched: string[] = [];
export const translateSync = (key: TranslateKey, obj?: Record<string, string | number> | null) => {
    const list = key.split('.')[0] as List;
    const k = key.split('.')[1];
    const lists = getDefaultStore().get(i18nLists);
    if (!lists[list]) return '-';
    const value = lists[list]?.[k] || k;
    if (!obj) return value;
    return Object.keys(obj).reduce(
        (acc, curr) => acc.replace(`{{${curr}}}`, obj[curr] as string),
        value || '__'
    );
};

export type Translator = (
    key: TranslateKey,
    obj?: Record<string, string | number> | null
) => string;

export function useTranslate(): { t: Translator; locale: string } {
    const { locale } = useSelector(localeSelector);
    const [lists, setLists] = useAtom(i18nLists);

    return {
        locale,
        t: (key: TranslateKey, obj?: Record<string, string | number> | null) => {
            const list = key.split('.')[0] as List;
            const k = key.split('.')[1];
            if (!lists[list] && !listsBeingFetched.includes(list)) {
                listsBeingFetched.push(list);
                fetch(`/assets/i18n/${locale}/${list}.json`)
                    .then((res) => res.json())
                    .then((data) => {
                        listsBeingFetched.splice(listsBeingFetched.indexOf(list), 1);
                        setLists((prev) => ({ ...prev, [list]: data }));
                    });
            }

            const value = lists[list]?.[k] || k;

            if (!obj) return value;

            return Object.keys(obj).reduce(
                (acc, curr) => acc.replace(`{{${curr}}}`, obj[curr] as string),
                value || '__'
            );
        }
    };
}
