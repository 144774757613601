import React, { useEffect } from 'react';
import { FileEnum } from '@he-novation/config/types/file.types';
import { useAtom } from 'jotai/index';

import { creatingNoteAtom, selectedNoteAtom } from '$atoms/note-atoms';
import { FormNote } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/FormNote';
import { NoteList } from '$components/SidePanel/SidePanelFile/SidePanelNotes/NoteList/NoteList';
import { SidePanelNotesHeader } from '$components/SidePanel/SidePanelFile/SidePanelNotes/SidePanelNotesHeader/SidePanelNotesHeader';
import { useAreaSelection } from '$hooks/useAreaSelection';
import { useAudioControls } from '$hooks/useAudioControls';
import { useNotes } from '$hooks/useNotes';
import { useVideoControls } from '$hooks/useVideoControls';

type SidePanelNotesProps = {
    castFileUuid?: string;
    fileType: FileEnum;
    openNoteCreation?: boolean;
};

export function SidePanelNotes({ castFileUuid, fileType }: SidePanelNotesProps) {
    const [creatingNote, setCreatingNote] = useAtom(creatingNoteAtom);

    const [selectedNote, setSelectedNote] = useAtom(selectedNoteAtom);

    const videoControls = useVideoControls();
    const audioControls = useAudioControls();

    const { displayShape, resetShapes } = useAreaSelection();
    const { notes, ...filterOptions } = useNotes();

    useEffect(() => {
        resetShapes();
        if (selectedNote) {
            setCreatingNote(false);
            videoControls.pause();
            const note = notes.find((i) => i.uuid === selectedNote);
            if (!note) return;

            if (note.metadata.tcIn) {
                if (fileType === 'video') videoControls.setTimecode(note.metadata.tcIn);
                if (fileType === 'audio') audioControls.setTimecode(note.metadata.tcIn);
            }
            if (note.metadata?.rectangle) {
                const areaSelection = {
                    ...note.metadata,
                    rectangle: note.metadata.rectangle!
                };
                displayShape([areaSelection]);
            }
        }
    }, [selectedNote, notes]);

    useEffect(() => {
        if (creatingNote) setSelectedNote(null);
    }, [creatingNote]);

    useEffect(() => {
        return () => {
            setCreatingNote(false);
        };
    }, []);

    return (
        <>
            <SidePanelNotesHeader
                fileType={fileType}
                {...filterOptions}
                castFileUuid={castFileUuid}
            />
            <NoteList key={filterOptions.sorting} items={notes} creatingNote={creatingNote} />
            <FormNote
                toggled={creatingNote}
                setToggled={setCreatingNote}
                castFileUuid={castFileUuid}
            />
        </>
    );
}
