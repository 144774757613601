import styles from './UploadTracker.module.css';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { ProgressCircle } from '@he-novation/design-system/components/widgets/ProgressCircle/ProgressCircle';
import { UPLOAD_MANAGER } from '@he-novation/paths/modals.constants';

import type { FinishedUpload, PendingUpload, Upload } from '$helpers/Uploader.types';
import { useUploader } from '$hooks/useUploader';
import { openModal } from '$redux/route/routeActions';

export function UploadTracker() {
    const dispatch = useDispatch();

    const { uploads, pending, finished, errors } = useUploader();
    const { total, uploaded } = computeProgress(uploads, pending, finished);

    if (!total && !finished.length && !errors.length) return null;
    return (
        <>
            <ProgressCircle
                className={styles.tracker}
                total={total}
                value={uploaded}
                onClick={() => dispatch(openModal(UPLOAD_MANAGER))}
            >
                <Icon icon="arrow_up" />
            </ProgressCircle>
        </>
    );
}

function computeProgress(uploads: Upload[], pending: PendingUpload[], finished: FinishedUpload[]) {
    let uploaded = finished.reduce((n, { file }) => n + file.size, 0);
    let total = uploaded + pending.reduce((n, { file }) => n + file.size, 0);
    for (const { progression } of uploads) {
        total += progression.total;
        uploaded += progression.loaded;
    }

    return { total, uploaded };
}
