export function getGlobalCssValue(name: string): string {
    if (typeof window === 'undefined') {
        return '';
    }
    return window.getComputedStyle(document.documentElement).getPropertyValue(name);
}

export const colorAlert = getGlobalCssValue('--color-alert');
export const colorBgLightest = getGlobalCssValue('--color-bg-1');
export const colorBgLight = getGlobalCssValue('--color-bg-2');
export const colorBgDark = getGlobalCssValue('--color-bg-3');
export const colorBgDarkest = getGlobalCssValue('--color-bg-4');
export const colorPrimary = getGlobalCssValue('--color-primary');
export const colorSuccess = getGlobalCssValue('--color-success');
export const colorFolderItemIcon = getGlobalCssValue('--color-folder-item-icon');
export const colorGold = getGlobalCssValue('--color-gold');
export const colorText = getGlobalCssValue('--color-text');
export const colorTextFaded = getGlobalCssValue('--color-text-faded');
export const colorTextFaded20 = getGlobalCssValue('--color-text-faded-20');

export const mobileThreshold = 768;
export const mobileHeightThreshold = 400;

export const zIndexMenu = parseInt(getGlobalCssValue('--z-index-menu') || '100');

/* -------------------- */

export const DISPLAY = {
    LIST: 'list',
    MOSAIC: 'mosaic'
};

export enum DISPLAY_TYPE {
    LIST = 'list',
    MOSAIC = 'mosaic'
}
