import { UserFileCreateBody } from '@he-novation/config/types/payloads/userFile.payload';
import { UserFile, UserFileType } from '@he-novation/config/types/user.types';
import { pathWithParams, userFilePaths } from '@he-novation/paths/herawApiPaths';
import { apiFetch, fetchAsFormData } from './apiFetch';
export const asyncUserFilesFetch = (type?: UserFileType): Promise<UserFile[]> =>
    fetchAsFormData(pathWithParams(userFilePaths.multiple, undefined, { type }), {
        method: 'GET'
    });

export const asyncUserFileCreate = ({ type, files }: UserFileCreateBody): Promise<UserFile> =>
    fetchAsFormData(userFilePaths.single, {
        method: 'POST',
        body: {
            type,
            files
        }
    });

export const asyncUserFileDelete = (userFileUid: string) =>
    apiFetch(pathWithParams(userFilePaths.specific, { userFileUid }), {
        method: 'DELETE'
    });
