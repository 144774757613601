import './Tooltip.scss';
import React, { ElementType, ReactNode, useRef } from 'react';
import { AbsoluteContentButton } from '../../buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Direction } from '../../../utils/getAbsolutePosition';
import classNames from 'classnames';
import { WidgetTone } from '../../../enums';
import { Button } from '../../buttons/Button/Button';

type TooltipProps = {
    className?: string;
    content: ReactNode | ((hide: Function) => ReactNode);
    delay?: number;
    direction?: Direction;
    hasArrow?: boolean;
    style?: any;
    tagName?: ElementType;
    tone?: WidgetTone;
    tooltipClassName?: string;
    children?: ReactNode | ReactNode[];
    triggersOnHover?: boolean;
    confirm?: () => void;
    [key: string]: any;
};

export const Tooltip: React.FC<TooltipProps> = ({
    children,
    className,
    content,
    delay,
    direction,
    hasArrow,
    style,
    tagName,
    tone = WidgetTone.Dark,
    tooltipClassName,
    triggersOnHover,
    confirm,
    ...rest
}) => {
    if (typeof triggersOnHover === 'undefined') {
        triggersOnHover = !confirm;
    }
    const ref = useRef<{ setIsVisible: (visible: boolean) => void }>();
    return (
        <AbsoluteContentButton
            className={classNames('c-tooltip', className)}
            contentClassName={classNames(
                'c-tooltip-content',
                tone,
                tooltipClassName,
                hasArrow && 'has-arrow'
            )}
            delay={delay}
            controlRef={ref}
            tagName={tagName}
            triggersOnHover={triggersOnHover}
            triggersOnClick={!triggersOnHover}
            closeOnTriggerClick={!confirm}
            contentZIndex={100}
            content={
                <div className="tooltip-content-inner">
                    {typeof content === 'function'
                        ? content(() => ref.current?.setIsVisible(false))
                        : content}
                    {confirm && (
                        <div className="confirmation">
                            <Button
                                type="button"
                                className="cancel"
                                icon="cross"
                                onClick={() => {
                                    ref.current?.setIsVisible(false);
                                }}
                            />
                            <Button
                                type="button"
                                className="confirm"
                                onClick={() => {
                                    confirm();
                                    ref.current?.setIsVisible(false);
                                }}
                                icon="check"
                            />
                        </div>
                    )}
                </div>
            }
            direction={direction}
            style={style}
            {...rest}
        >
            {children}
        </AbsoluteContentButton>
    );
};
