import { FileState } from '@he-novation/config/types/file.types';
import { pick, pipe, property } from 'lodash/fp';
import { stateSelector as contentSelector } from '../contentSelectors';
import { FILE } from './fileActions';

import { ReduxState } from '$redux/store';

export const stateSelector: (state: ReduxState) => FileState = pipe(
    contentSelector,
    property(FILE)
);

export const fileErrorSelector = pipe(stateSelector, property('error'));

export const iframeCaptureSelector = pipe(stateSelector, pick('iframeCapture'));

export const pageAndPageNumberSelector = pipe(stateSelector, pick(['page', 'pageNumber']));

export const fileTypeSelector = pipe(stateSelector, property('type'), (fileType) => ({
    fileType: fileType!
}));

export const frameRateSelector = pipe(stateSelector, pick('frameRate'));

export const folderUuidSelector = pipe(stateSelector, pick('folderUuid'));

export const fileVersionSelector = pipe(stateSelector, property('version'), (version) => ({
    fileVersion: version
}));

export const fileHighlightedVersionSelector = pipe(
    stateSelector,
    pick('highlightedVersion'),
    ({ highlightedVersion }) => ({ fileHighlightedVersion: highlightedVersion })
);

export const versionsSelector = pipe(
    stateSelector,
    property('assets'),
    (assets = []) =>
        assets.filter(
            (asset) => asset.quality === 'source' && asset.type !== 'subtitle' && !asset.expires
        ),
    (versions) => ({ versions })
);

export const fileUuidSelector = pipe(stateSelector, pick('uuid'), ({ uuid }) => ({
    fileUuid: uuid
}));

export const fileNameUuidAndVersionSelector = pipe(
    stateSelector,
    pick(['name', 'uuid', 'version']),
    ({ name, uuid, version }) => ({
        fileName: name!,
        fileUuid: uuid!,
        fileVersion: version!
    })
);

export const comparisonSelector = pipe(
    stateSelector,
    pick('comparisonAsset'),
    ({ comparisonAsset }) => {
        const comparison = comparisonAsset ? { version: comparisonAsset.version } : undefined;
        return { comparison };
    }
);

export const heightAndWidthSelector = pipe(stateSelector, pick(['height', 'width']));

export const HDandSDSizeSelector = pipe(stateSelector, pick(['sizeHD', 'sizeSD']));

export const HDandSDBitRateSelector = pipe(stateSelector, pick(['bitRateHD', 'bitRateSD']));

export const timecodestartSelector = pipe(stateSelector, pick('timecodestart'));

export const audioTracksAndActiveAudioTrackSelector = pipe(
    stateSelector,
    pick(['audioTracks', 'activeAudioTrack'])
);

export const commentCreationDataSelector = pipe(
    stateSelector,
    pick([
        'folderUuid',
        'uuid',
        'version',
        'type',
        'activeAudioTrack',
        'activeSubtitles',
        'iframeCapture',
        'page'
    ]),
    ({ uuid, version, type, ...payload }) => ({
        commentCreationData: {
            ...payload,
            fileUuid: uuid,
            fileVersion: version,
            fileType: type
        }
    })
);

export const fileProjectUuidSelector = pipe(
    stateSelector,
    property('project_uuid'),
    (fileProjectUuid) => ({
        fileProjectUuid
    })
);

export const fileDurationSelector = pipe(stateSelector, property('duration'));

export const activeAssetSelector = pipe(stateSelector, property('activeAsset'));
export const comparisonAssetSelector = pipe(stateSelector, property('comparisonAsset'));
export const assetsSelector = pipe(stateSelector, property('assets'));

export const breadcrumbSelector = pipe(stateSelector, property('breadcrumb'));

export const isCurrentVersionDeletedSelector = pipe(
    stateSelector,
    pick(['assets', 'activeAsset']),
    ({ assets = [], activeAsset }) => ({
        isCurrentVersionDeleted:
            activeAsset && assets.find(({ version }) => version === activeAsset.version)?.expires
    })
);
