import { FullFolder } from '@he-novation/config/types/folder.types';
import { apiFolderContentFileToFrontFolderContentFile } from '@he-novation/front-shared/mappers/file.mappers';
import {
    FrontFolderContentFile,
    FrontFolderContentFileStatus
} from '@he-novation/front-shared/types/file.front-types';
import {
    FolderState,
    FrontFolderContentGhost
} from '@he-novation/front-shared/types/folder.front-types';
import update, { Spec } from 'immutability-helper';
import { FILE_ENCODE, FILE_RESTORE } from '../../content/file/fileActions';
import mapFetchFileInfoToUpdate from '../../content/folder/maps/mapFetchFileInfoToUpdate';
import mapFetchFileReviewersToUpdate from '../../content/folder/maps/mapFetchFileReviewersToUpdate';
import { SET } from '../../route/routeActions';
import {
    FETCH_FILE_VIEW,
    FETCH_FOLDER_VIEW,
    FETCH_TRASH_VIEW,
    PUBLIC_FILE_VIEW_FETCH,
    RESET_CONTENT
} from '../contentActions';
import {
    DELETE_FILE,
    FETCH_CONTENT,
    FETCH_FILE_INFO,
    FETCH_FILE_REVIEWERS,
    FETCH_FOLDER,
    FETCH_PLUGINS_ACCESS,
    FOLDER_ACCESS_MODIFY,
    FOLDER_CONTENT_GHOST_CREATE,
    FOLDER_CONTENT_GHOST_DELETE,
    FOLDER_ITEMS_COPY,
    FOLDER_ITEMS_PASTE,
    FOLDER_LOADED,
    FOLDER_MEMBERS_FETCH,
    FOLDER_RENAME,
    FOLDER_RESTORE,
    FOLDER_TREE_COPY,
    FOLDER_UPDATE,
    GRANT_ACCESS,
    REVOKE_ACCESS,
    SETTINGS_SET,
    UPDATE_UUID,
    USER_METADATA_SET,
    WS_DELETE_FILE,
    WS_FILE_ADD,
    WS_FILE_CAST,
    WS_FILE_CAST_DELETE,
    WS_FILE_CONVERT_TO_VERSION,
    WS_FILE_UPLOAD_SOURCE_READY,
    WS_FILE_VERSION_DELETE,
    WS_FILE_VERSION_RESTORE,
    WS_FOLDER_ADD,
    WS_FOLDER_ASSET_READY,
    WS_FOLDER_ASSET_UPLOAD_PROGRESS,
    WS_FOLDER_CAST,
    WS_FOLDER_CAST_DELETE,
    WS_FOLDER_DELETE,
    WS_FOLDER_MEMBERS_ADD,
    WS_FOLDER_MEMBERS_REMOVE,
    WS_FOLDER_SOURCE_ASSET_TYPE,
    WS_FOLDER_TRANSCODING_FINISHED,
    WS_FOLDER_TRANSCODING_PENDING,
    WS_FOLDER_TRANSCODING_PROGRESS,
    WS_SET_FOLDER,
    WS_SET_FOLDER_FILE,
    WS_UPLOAD_ASSET_FINISH,
    WS_UPLOAD_ASSET_UPDATE
} from './folderActions';

import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';

export const folderInitialState: FolderState = {
    folder: null,
    isShared: false,
    isLoaded: false,
    content: [],
    fileVersions: {},
    fileReviews: {},
    fileSubtitles: {},
    members: [],
    fileInfo: mapFetchFileInfoToUpdate({}).fileInfo,
    plugins: []
};

export default (state = folderInitialState, action: any = {}) => {
    switch (action.type) {
        case RESET_CONTENT:
            return update(state, {
                $unset: ['uuid']
            });

        case SET: {
            if (
                action.route === window.location.href ||
                action.route === window.location.pathname
            ) {
                return state;
            }

            return update(folderInitialState, {
                isShared: { $set: state.isShared },
                copy: {
                    $set: state.copy
                }
            });
        }

        case asyncActionSuccess(SETTINGS_SET): {
            if (action.folder.uuid !== state.folder?.uuid) return state;
            if (!action.folder.metadata) action.folder.metadata = {};
            if (!action.folder.metadata.user) {
                action.folder.metadata.user = state.metadata?.user;
            }
            const partial: Partial<FullFolder> = {
                admittance: action.folder.admittance,
                updated: new Date(action.folder.updated),
                isEncrypted: !!action.folder.encrypted,
                defaultPresets: !!action.folder.default_presets,
                exportMode: action.folder.export_mode,
                isPublic: !!action.folder.public,
                publicDownload: action.folder.public_download,
                publicPassword: action.folder.public_password,
                metadata: action.folder.metadata,
                tags: action.folder.tags,
                labels: Array.isArray(action.folder.labels)
                    ? action.folder.labels
                    : (action.folder.labels || '').split(','),
                watermark: action.folder.watermark,
                triggers: action.folder.triggers
            };

            return update(state, {
                folder: { $merge: partial }
            });
        }

        case FILE_ENCODE: {
            const fileIndex = state.content.findIndex(({ uuid }) => uuid === action.uuid);
            if (
                fileIndex === -1 ||
                state.content[fileIndex].type !== 'file' ||
                !(state.content[fileIndex] as FrontFolderContentFile).noAssets
            )
                return state;
            return update(state, {
                content: {
                    [fileIndex]: {
                        $unset: ['noAssets']
                    }
                }
            });
        }

        case asyncActionSuccess(USER_METADATA_SET):
            if (action.folderUuid !== state.folder?.uuid) return state;
            return update(state, {
                metadata_user: {
                    $set: action.metadata
                }
            });

        case asyncActionSuccess(FETCH_FOLDER):
            return update(state, {
                folder: {
                    $set: action.response
                }
            });

        case FOLDER_LOADED:
            return update(state, {
                isLoaded: { $set: true }
            });

        case asyncActionSuccess(FETCH_FILE_INFO):
            return update(state, {
                $merge: action.fileState
            });

        case asyncActionSuccess(FETCH_CONTENT):
            return update(state, {
                content: {
                    $set: action.response || []
                }
            });

        case asyncActionSuccess(FETCH_FILE_VIEW):
        case asyncActionSuccess(PUBLIC_FILE_VIEW_FETCH):
        case asyncActionSuccess(FETCH_TRASH_VIEW):
            return update(state, { $merge: action.folderState });

        case FETCH_FOLDER_VIEW: {
            return update(state, {
                isLoaded: { $set: false }
            });
        }
        case asyncActionSuccess(FETCH_FOLDER_VIEW):
            return update(state, {
                $merge: { ...action.folderState, isLoaded: true }
            });
        case asyncActionError(FETCH_FOLDER_VIEW):
            return update(state, { $merge: { error: action.error, isLoaded: true } });

        case asyncActionSuccess(FETCH_FILE_REVIEWERS):
            return update(state, {
                $merge: mapFetchFileReviewersToUpdate(action.response, action.uuid)
            });

        case asyncActionSuccess(FOLDER_ACCESS_MODIFY): {
            if (state.folder?.uuid !== action.folderUuid) return state;
            const memberIndex = state.members.findIndex((m) => m.uuid === action.userUuid);
            if (memberIndex < 0) return state;
            return update(state, {
                members: {
                    [memberIndex]: {
                        $merge: {
                            canDownload: action.canDownload,
                            canExport: action.canExport,
                            role: action.role
                        }
                    }
                }
            });
        }

        case FOLDER_ITEMS_COPY:
            return update(state, {
                copy: { $set: { data: action.data, cut: action.cut } }
            });
        case FOLDER_TREE_COPY:
            return update(state, {
                copy: { $set: { data: action.data, type: 'tree', cut: action.cut } }
            });

        case FOLDER_ITEMS_PASTE: {
            if (!state.folder || action.folderUuid !== state.folder.uuid) return state;

            const items = action.items
                .filter((i) => i.type !== 'folder')
                .map((item) => ({
                    ...item,
                    created: new Date(item.created),
                    updated: new Date(item.updated),
                    processingStatus: state.copy.cut
                        ? item.processingStatus
                        : {
                              status: FrontFolderContentFileStatus.COPYING
                          }
                }));

            return update(state, {
                copy: { $set: null },
                content: {
                    $push: items
                }
            });
        }

        case asyncActionSuccess(FOLDER_UPDATE): {
            const folderIndex = state.content.findIndex(({ uuid }) => uuid === action.folder.uuid);
            if (folderIndex < 0) {
                return update(state, { $merge: action.folder });
            }
            return update(state, {
                content: {
                    [folderIndex]: { $merge: action.folder }
                }
            });
        }

        case DELETE_FILE:
        case WS_DELETE_FILE:
            return update(state, {
                content: {
                    $set: state.content.filter(({ uuid }) => uuid !== action.file.uuid)
                }
            });

        case asyncActionSuccess(FETCH_PLUGINS_ACCESS): {
            if (action.plugins) {
                return update(state, {
                    plugins: {
                        $set: action.plugins
                    }
                });
            }
            return state;
        }

        case asyncActionSuccess(GRANT_ACCESS):
            if (action.folderUuid !== state.uuid) return state;

            if (action.members.length === 1) {
                const inPendingIndex = state.members.findIndex(
                    (member) => member.uuid === action.members[0].uuid
                );
                if (inPendingIndex >= 0) {
                    return update(state, {
                        members: {
                            [inPendingIndex]: {
                                $set: { ...action.members[0], pending: false }
                            }
                        }
                    });
                }
            }

            return update(state, {
                members: {
                    $set: state.members.concat(action.members)
                }
            });

        case asyncActionSuccess(REVOKE_ACCESS):
            if (action.folderUuid !== state.uuid) return state;
            return update(state, {
                members: {
                    $set: state.members.filter((m) => m.uuid !== action.userUuid)
                }
            });

        case UPDATE_UUID: {
            const itemIndex = state.content.findIndex(({ uuid }) => uuid === action.oldUuid);
            if (itemIndex < 0) return state;
            return update(state, {
                content: {
                    [itemIndex]: {
                        uuid: {
                            $set: action.newUuid
                        }
                    }
                }
            });
        }

        case WS_FILE_ADD: {
            if (action.file.folder?.uuid !== state.folder?.uuid) return state;
            const fileIndex = state.content.findIndex((f) => f.uuid === action.file.uuid);

            if (typeof action.file.created === 'string')
                action.file.created = new Date(action.file.created);
            if (typeof action.file.updated === 'string')
                action.file.updated = new Date(action.file.updated);

            let _update: Spec<FolderState>;
            const frontFolderContentFile = apiFolderContentFileToFrontFolderContentFile({
                ...action.file,
                type: 'file',
                copying: action.copying
            });
            if (fileIndex > -1) {
                _update = {
                    content: {
                        [fileIndex]: {
                            $merge: frontFolderContentFile
                        }
                    }
                };
            } else {
                _update = {
                    content: {
                        $push: [frontFolderContentFile]
                    }
                };
            }
            const _state = update(state, _update);

            const ghostIndex = _state.content.findIndex(
                (f) => f.type === 'ghost' && f.name === action.file.name
            );
            if (ghostIndex > -1) {
                return update(_state, {
                    content: {
                        $splice: [[ghostIndex, 1]]
                    }
                });
            }
            return _state;
        }

        case WS_FOLDER_SOURCE_ASSET_TYPE: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;

            const contentFile = state.content[fileIndex] as FrontFolderContentFile;

            if (!contentFile.isPlayable && action.sourceAssetType === 'player') {
                return update(state, {
                    content: {
                        [fileIndex]: {
                            isPlayable: { $set: true }
                        }
                    }
                });
            }
            return state;
        }

        case WS_FOLDER_TRANSCODING_PENDING: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;

            const contentFile = state.content[fileIndex] as FrontFolderContentFile;

            if (contentFile.processingStatus?.status === FrontFolderContentFileStatus.TRANSCODING) {
                return state;
            }

            return update(state, {
                content: {
                    [fileIndex]: {
                        processingStatus: {
                            $set: { status: FrontFolderContentFileStatus.WAITING_FOR_TRANSCODE }
                        }
                    }
                }
            });
        }

        case WS_FOLDER_TRANSCODING_PROGRESS: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;
            const remainingTranscodingMs = new Date(action.estimatedEndDate).getTime() - Date.now();

            return update(state, {
                content: {
                    [fileIndex]: {
                        processingStatus: {
                            $set: {
                                status: FrontFolderContentFileStatus.TRANSCODING,
                                remainingTranscodingMs
                            }
                        }
                    }
                }
            });
        }

        case WS_FOLDER_TRANSCODING_FINISHED: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;

            return update(state, {
                content: {
                    [fileIndex]: {
                        $unset: ['processingStatus']
                    }
                }
            });
        }

        case WS_FILE_UPLOAD_SOURCE_READY: {
            const index = state.content.findIndex(({ uuid }) => uuid === action.data.file.uuid);
            if (index < 0) return state;

            const contentFile: FrontFolderContentFile = {
                ...apiFolderContentFileToFrontFolderContentFile(action.data.file)
            };
            return update(state, {
                content: {
                    [index]: {
                        $merge: contentFile
                    }
                }
            });
        }

        case WS_FOLDER_ADD: {
            if (
                action.data.folder.folder?.uuid !== state.uuid &&
                action.data.folder.folder &&
                state.uuid
            ) {
                return state;
            }
            if (state.content.find((f) => f.uuid === action.data.folder.uuid)) {
                return state;
            }
            return update(state, {
                content: {
                    $push: [
                        {
                            ...action.data.folder,
                            type: 'folder',
                            created: new Date(action.data.folder.created),
                            updated: new Date(action.data.folder.updated)
                        }
                    ]
                }
            });
        }

        //@deprecated
        case WS_UPLOAD_ASSET_UPDATE: {
            if (action.data.asset.type !== 'player' && action.data.asset.quality !== 'sd')
                return state;
            const fileIndex = state.content.findIndex((f) => f.uuid === action.data.file.uuid);
            if (fileIndex === -1) return state;
            return update(state, {
                content: {
                    [fileIndex]: {
                        processingStatus: {
                            $set: {
                                status: FrontFolderContentFileStatus.TRANSCODING,
                                remainingTranscodingMs: parseFloat(action.data.asset.remaining)
                            }
                        }
                    }
                }
            });
        }

        //@deprecated
        case WS_UPLOAD_ASSET_FINISH: {
            if (
                !action.data.file.assets.find(
                    (a) => a.type === 'player' && a.version === action.data.file.version && a.url
                )
            )
                return state;
            const index = state.content.findIndex(({ uuid }) => uuid === action.data.file.uuid);
            if (index < 0) return state;

            return update(state, {
                content: {
                    [index]: {
                        $merge: {
                            ...apiFolderContentFileToFrontFolderContentFile(action.data.file)
                        }
                    }
                }
            });
        }

        case WS_FOLDER_DELETE: {
            const folderIndex = state.content.findIndex(
                ({ uuid }) => uuid === action.data.folder.uuid
            );
            if (folderIndex < 0) return state;
            return update(state, {
                content: {
                    $splice: [[folderIndex, 1]]
                }
            });
        }

        case WS_SET_FOLDER: {
            delete action.folder.role;
            delete action.folder.metadata_user;
            delete action.folder.parent;
            if (typeof action.folder?.tags === 'string') {
                action.folder.tags = action.folder?.tags.split('');
            }
            if (typeof action.folder?.labels === 'string') {
                action.folder.labels = action.folder?.labels.split('');
            }

            if (state.folder?.uuid === action.folder.uuid) {
                return update(state, {
                    folder: {
                        $merge: action.folder
                    }
                });
            }

            const folderIndex = state.content.findIndex(({ uuid }) => uuid === action.folder.uuid);
            if (folderIndex < 0) return state;
            return update(state, {
                content: {
                    [folderIndex]: {
                        $merge: action.folder
                    }
                }
            });
        }

        case asyncActionSuccess(FOLDER_RENAME): {
            const folderIndex = state.content.findIndex(({ uuid }) => uuid === action.folder.uuid);
            if (folderIndex < 0) return state;
            return update(state, {
                content: {
                    [folderIndex]: {
                        $merge: {
                            name: action.folder.name
                        }
                    }
                }
            });
        }

        case WS_SET_FOLDER_FILE: {
            const fileIndex = state.content.findIndex(({ uuid }) => uuid === action.file.uuid);
            if (fileIndex > -1) {
                return update(state, {
                    content: {
                        [fileIndex]: {
                            $merge: {
                                ...action.file,
                                created: action.file.created
                                    ? new Date(action.file.created)
                                    : undefined,
                                updated: action.file.updated
                                    ? new Date(action.file.updated)
                                    : undefined
                            }
                        }
                    }
                });
            }
            return state;
        }

        case asyncActionSuccess(FILE_RESTORE):
            return update(state, {
                content: {
                    $set: state.content.filter((c) => c.uuid !== action.fileUuid)
                }
            });
        case asyncActionSuccess(FOLDER_RESTORE):
            return update(state, {
                content: {
                    $set: state.content.filter((c) => c.uuid !== action.folderUuid)
                }
            });
        case asyncActionSuccess(FOLDER_MEMBERS_FETCH):
            if (state.folder?.uuid !== action.folderUuid) return state;
            return update(state, {
                members: {
                    $set: action.members
                }
            });

        case WS_FILE_CAST: {
            const fileIndex = state.content.findIndex((c) => c.uuid === action.data.fileUuid);
            if (fileIndex === -1) return state;
            return update(state, {
                content: {
                    [fileIndex]: {
                        casts: {
                            $set: (state.content[fileIndex] as FrontFolderContentFile).casts + 1
                        }
                    }
                }
            });
        }

        case WS_FILE_CAST_DELETE: {
            const i = state.content.findIndex((c) => c.uuid === action.data.fileUuid);
            if (i === -1) return state;
            /**
            return update(state, {
                content: {
                    [i]: {
                        casted: {
                            $set: state.content[i].casted - 1
                        }
                    }
                }
            });
             **/
            return state;
        }

        case WS_FOLDER_CAST: {
            const i = state.content.findIndex((c) => c.uuid === action.data.folderUuid);
            if (i === -1) return state;
            /**
            return update(state, {
                content: {
                    [i]: {
                        casted: {
                            $set: state.content[i].casted + 1
                        }
                    }
                }
            });
             **/
            return state;
        }

        case WS_FOLDER_CAST_DELETE: {
            const i = state.content.findIndex((c) => c.uuid === action.data.folderUuid);
            if (i === -1) return state;
            /**
            return update(state, {
                content: {
                    [i]: {
                        casted: {
                            $set: state.content[i].casted - 1
                        }
                    }
                }
            });
             **/
            return state;
        }

        case WS_FOLDER_MEMBERS_ADD: {
            const members = state.members
                .filter((m) => !action.members.map((m) => m.uuid).includes(m.uuid))
                .concat(action.members);
            return update(state, {
                members: {
                    $set: members
                }
            });
        }

        case WS_FOLDER_MEMBERS_REMOVE: {
            return update(state, {
                members: {
                    $set: state.members?.filter((m) => !action.uuids.includes(m.uuid))
                }
            });
        }

        case WS_FILE_CONVERT_TO_VERSION:
            if (action.targetFile.folder.uuid !== state.folder?.uuid) return state;
            return update(state, {
                content: {
                    $splice: [
                        [state.content.findIndex((f) => f.uuid === action.sourceFile.uuid), 1]
                    ],
                    [state.content.findIndex((f) => f.uuid === action.targetFile.uuid)]: {
                        $merge: apiFolderContentFileToFrontFolderContentFile({
                            ...action.targetFile,
                            copying: true
                        })
                    }
                }
            });

        case WS_FOLDER_ASSET_UPLOAD_PROGRESS: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;

            return update(state, {
                content: {
                    [fileIndex]: {
                        processingStatus: {
                            $set: {
                                status: FrontFolderContentFileStatus.UPLOADING,
                                uploadProgress: action.progress.progress
                            }
                        }
                    }
                }
            });
        }
        case WS_FOLDER_ASSET_READY: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;
            const _update: Partial<FrontFolderContentFile> = {};
            const item = state.content[fileIndex];

            if (item.type !== 'file' || action.asset.version !== item.version) {
                return state;
            }

            if (
                item.processingStatus?.status === FrontFolderContentFileStatus.COPYING &&
                action.copying &&
                action.copying.copied === action.copying.total
            ) {
                state = update(state, {
                    content: {
                        [fileIndex]: {
                            $unset: ['processingStatus']
                        }
                    }
                });
            }

            if (action.asset.type === 'player' && action.asset.url) {
                _update.isPlayable = true;
            }

            if (action.asset.type === 'thumbnail' && action.asset.url) {
                _update.thumbnail = action.asset.url;
            }

            if (action.asset.type === 'preview' && action.asset.url) {
                _update.preview = action.asset.url;
            }

            return update(state, {
                content: {
                    [fileIndex]: {
                        $merge: _update
                    }
                }
            });
        }

        case WS_FILE_VERSION_DELETE: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;

            const _update: Partial<FrontFolderContentFile> = {};
            _update.version = action.newFileVersion;
            _update.status = action.status;
            _update.versions = (state.content[fileIndex] as FrontFolderContentFile).versions.map(
                (v) => ({
                    ...v,
                    expired: v.version === action.version
                })
            );
            return update(state, {
                content: {
                    [fileIndex]: {
                        $merge: _update
                    }
                }
            });
        }
        case WS_FILE_VERSION_RESTORE: {
            const fileIndex = state.content.findIndex((f) => f.uuid === action.fileUuid);
            if (fileIndex === -1) return state;

            const _update: Partial<FrontFolderContentFile> = {};
            _update.version = action.newFileVersion;
            _update.versions = (state.content[fileIndex] as FrontFolderContentFile).versions.map(
                (v) => ({
                    ...v,
                    expired: v.version === action.version ? false : v.expired
                })
            );
            return update(state, {
                content: {
                    [fileIndex]: {
                        $merge: _update
                    }
                }
            });
        }
        case FOLDER_CONTENT_GHOST_CREATE: {
            if (state.folder?.uuid !== action.ghost.folderUuid) return state;
            return update(state, {
                content: {
                    $push: [action.ghost as FrontFolderContentGhost]
                }
            });
        }

        case FOLDER_CONTENT_GHOST_DELETE:
            {
                return update(state, {
                    content: {
                        $set: state.content.filter((c) => c.uuid !== action.uuid)
                    }
                });
            }
            break;
        default:
            break;
    }

    return state;
};
