import {
    Contact,
    ContactType,
    GroupPayload,
    GroupWithContacts
} from '@he-novation/config/types/contact.types';
import { ContactSchema } from '@he-novation/config/types/payloads/contact.payload';
import { apiFetch, fetchAsFormData } from '@he-novation/front-shared/async/apiFetch';
import paths, { pathWithParams } from '@he-novation/paths/herawApiPaths';

export const contactAdd = ({
    email,
    firm,
    firstname,
    lastname,
    phone,
    role,
    isClientContact,
    clientContactLabelUids
}: ContactSchema): Promise<Contact> =>
    apiFetch(paths.contact.single, {
        method: 'POST',
        body: {
            email,
            firm,
            firstname,
            lastname,
            phone,
            role,
            isClientContact,
            clientContactLabelUids
        }
    });

export const contactDelete = (contactUuid: string) =>
    apiFetch(
        pathWithParams(paths.contact.specific, {
            contactUuid
        }),
        {
            method: 'DELETE'
        }
    );

export const contactEdit = (contactUuid: string, body: ContactSchema) =>
    apiFetch(
        pathWithParams(paths.contact.specific, {
            contactUuid
        }),
        {
            method: 'PUT',
            body
        }
    );

export const contactAddToGroup = (contactUuids: string[], contactGroupUuid: string) =>
    apiFetch(
        pathWithParams(paths.contactGroupContact.single, {
            contactGroupUuid
        }),
        {
            method: 'POST',
            body: {
                contactUuids
            }
        }
    );

export const contactDeleteFromGroup = (contactUuids: string[], contactGroupUuid: string) =>
    apiFetch(
        pathWithParams(paths.contactGroupContact.single, {
            contactGroupUuid
        }),
        {
            method: 'DELETE',
            body: {
                contactUuids
            }
        }
    );
export const contactGroupsFetch = () =>
    apiFetch(paths.contactGroup.multiple, {
        method: 'GET'
    });

export const asyncContactGroupsWithContactsFetch = (): Promise<GroupWithContacts[]> =>
    apiFetch(paths.contactGroup.multipleContacts, {
        method: 'GET'
    });

export const contactGroupAdd = (body: GroupPayload) =>
    apiFetch(paths.contactGroup.single, {
        method: 'POST',
        body
    });

export const contactGroupEdit = (contactGroupUuid: string, body: Partial<GroupPayload>) =>
    apiFetch(pathWithParams(paths.contactGroup.specific, { contactGroupUuid }), {
        method: 'PUT',
        body
    });

export const contactGroupDelete = (contactGroupUuid: string) =>
    apiFetch(pathWithParams(paths.contactGroup.specific, { contactGroupUuid }), {
        method: 'DELETE'
    });

export const fetchContacts = (): Promise<ContactType[]> => apiFetch(paths.contact.root);

export const fetchGroup = (groupUuid: string) =>
    apiFetch(pathWithParams(paths.contact.group, { groupUuid }));

export const importContacts = (data) =>
    fetchAsFormData('/upload_csv', {
        method: 'POST',
        body: data
    });
