import { useAtom } from 'jotai';
import { panelAtom } from '../atoms/panel-atoms';

import sidePanels from '$components/SidePanel/sidePanels';

export function usePanel() {
    const [panel, setPanel] = useAtom(panelAtom);

    return {
        closePanel: () => setPanel({ panel: null, payload: undefined }),
        panel: panel?.panel,
        payload: panel?.payload,
        openPanel: (panel: keyof typeof sidePanels, payload?: Record<string, unknown>) => {
            setPanel({
                panel,
                payload
            });
        }
    };
}
