import { CastCreateBody, CastUpdateBody } from '@he-novation/config/types/payloads/cast.payload';
import {
    asyncCastCreate,
    asyncCastDelete,
    asyncCastEdit,
    asyncCastsFetch
} from '@he-novation/front-shared/async/cast.async';

import { asyncActionSuccess } from '$helpers/asyncAction';

export const CASTS = 'CASTS';
export const CASTS_FETCH = `${CASTS}/FETCH`;
export const CASTS_CREATE = `${CASTS}/CREATE`;
export const CASTS_EDIT = `${CASTS}/EDIT`;
export const CASTS_DELETE = `${CASTS}/DELETE`;

export const castsFetch = (projectUuid?: string) => async (dispatch) => {
    dispatch({ type: CASTS_FETCH });
    dispatch({
        type: asyncActionSuccess(CASTS_FETCH),
        casts: await asyncCastsFetch(),
        projectUuid,
        projectCasts: projectUuid ? await asyncCastsFetch(projectUuid) : undefined
    });
};

export const castCreate = (payload: CastCreateBody, cb) => async (dispatch) => {
    dispatch({
        type: CASTS_CREATE,
        saveDefaultCastDesign: payload.saveDefaultCastDesign,
        projectUuid: payload.projectUuid,
        defaultCastStyle: {
            logoUserFileUid: payload.logoUserFileUid,
            userFileUid: payload.userFileUid,
            backgroundColor: payload.backgroundColor,
            textColor: payload.textColor
        }
    });
    const cast = await asyncCastCreate(payload);
    dispatch({
        type: asyncActionSuccess(CASTS_CREATE),
        cast
    });
    if (typeof cb === 'function') await cb(cast);
};

export const castEdit =
    (uid, payload: CastUpdateBody, projectUuid?: string, cb?: Function) => async (dispatch) => {
        dispatch({ type: CASTS_EDIT });
        const cast = await asyncCastEdit(uid, payload);
        dispatch({
            type: asyncActionSuccess(CASTS_EDIT),
            projectUuid,
            cast: {
                ...cast,
                password: payload.password || cast.password,
                expires: payload.expires ? new Date(payload.expires) : undefined,
                maxViews: payload.maxViews
            }
        });
        if (typeof cb === 'function') cb();
    };

export const castDelete =
    (castUid: string, projectUuid?: string, cb?: Function) => async (dispatch) => {
        dispatch({ type: CASTS_DELETE });
        await asyncCastDelete(castUid);
        dispatch({ type: asyncActionSuccess(CASTS_DELETE), projectUuid, castUid });
        if (typeof cb === 'function') cb();
    };
