import { Locale } from '@he-novation/config/types/enums';
import { MS_PER_DAY, MS_PER_HOUR, MS_PER_MINUTE, MS_PER_WEEK } from '@he-novation/utils/datetime';
import __ from '@he-novation/utils/i18n';
import { doubleDigit } from '@he-novation/utils/number';

export const FORMAT_DDMMYYHHMMSS = 'DD:MM:YY - HH:MM:SS';
export const FORMAT_FULL_DATE_TRANSLATED = 'DAY:DATE:MONTH:YEAR • H:MM';
export const FORMAT_SHORT_DATETIME_TRANSLATED = 'DATE:SHORTMONTH • H:MM';
export const FORMAT_SHORT_DATE_TRANSLATED = 'DATE:SHORTMONTH';

export const DAYS_TRANSLATION = {
    fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
};

export const MONTHS_TRANSLATION = {
    fr: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
    en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    de: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
};

export function offsetYears(date: Date, months: number): Date {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + months);
    return result;
}

export function offsetMonths(date: Date, months: number): Date {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}

export function offsetWeeks(date: Date, weeks: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + weeks * 7);
    return result;
}

export function offsetDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function offsetHours(date: Date, hours: number): Date {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
}

export function offsetMinutes(date: Date, minutes: number): Date {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
}

export function offsetByPeriod(date: Date, period: string, amount: number = 1): Date {
    switch (period) {
        case 'hour':
            return offsetHours(date, amount);
        case 'day':
            return offsetDays(date, amount);
        case 'week':
            return offsetWeeks(date, amount);
        case 'month':
            return offsetMonths(date, amount);
        case 'year':
            return offsetYears(date, amount);
        default:
            return date;
    }
}

export function dateToString(created: Date): string {
    const dateObject = new Date(created);
    return `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
}

export function getFormattedDateAndHour(d: Date = new Date()): string {
    return (
        [d.getDate(), d.getMonth() + 1, d.getFullYear()]
            .map((n) => (n < 10 ? `0${n}` : `${n}`))
            .join('/') + ` - ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
    );
}

export function getMysqlStringFromDate(date: Date): string {
    return `${date.getFullYear()}-${
        date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function getLocalizedTime(
    locale: Locale | string,
    created: Date | number,
    type: 'date' | 'hour'
): string | null {
    try {
        const now = new Date();
        const timeStamp = typeof created === 'object' ? created : immutableDate(created);

        let opts;

        if (type === 'date') {
            opts = {
                month: 'long',
                day: 'numeric'
            };

            if (now.getFullYear() !== timeStamp.getFullYear()) {
                opts.year = 'numeric';
            }
        } else if (type === 'hour') {
            opts = {
                hour: 'numeric',
                minute: 'numeric'
            };
        }

        return new Intl.DateTimeFormat(locale, opts).format(timeStamp);
    } catch (e) {
        console.error(e);
        return null;
    }
}

export function getLocalizedDate(
    date: Date,
    locale: Locale | string,
    intlDateFormatOptions = {}
): string {
    return date.toLocaleString(localeToDateLocale(locale), intlDateFormatOptions);
}

export function getLocalizedDuration(hours: number, locale: string): string {
    const days = Math.floor(hours / 24);
    const remainingHours = Math.floor(hours % 24);
    const minutes = Math.floor((hours % 1) * 60);

    const numberFormat = new Intl.NumberFormat(locale, { minimumIntegerDigits: 2 });

    const dayString = days > 0 ? `${days}j` : '';
    const hourString = remainingHours > 0 ? `${remainingHours}h` : '';
    const minuteString = minutes > 0 ? `${numberFormat.format(minutes)}min` : '';

    return [dayString, hourString, minuteString].filter(Boolean).join(' ').trim();
}
export function localeToDateLocale(locale: Locale | string): string {
    switch (locale) {
        case 'fr':
            return 'fr-FR';
        case 'de':
            return 'de-DE';
        default:
            return 'en-GB';
    }
}

export function formatDate(
    date: Date = new Date(),
    format: string = FORMAT_DDMMYYHHMMSS,
    lang: Locale | string = Locale.FR
): string {
    switch (format) {
        case FORMAT_DDMMYYHHMMSS:
            return getFormattedDateAndHour(date);
        case FORMAT_FULL_DATE_TRANSLATED: {
            return `${DAYS_TRANSLATION[lang][date.getDay()]} ${date.getDate()} ${
                MONTHS_TRANSLATION[lang][date.getMonth()]
            } ${date.getFullYear()} • ${date.getHours()}h${
                date.getMinutes() > 0 ? doubleDigit(date.getMinutes()) : ''
            }`;
        }
        case FORMAT_SHORT_DATETIME_TRANSLATED:
            return `${date.getDate()} ${
                MONTHS_TRANSLATION[lang][date.getMonth()]
            } - ${date.getHours()}:${doubleDigit(date.getMinutes())}`;

        case FORMAT_SHORT_DATE_TRANSLATED:
            return `${date.getDate()} ${MONTHS_TRANSLATION[lang][date.getMonth()]}`;
        default:
            return date.toISOString();
    }
}

export function isEqualDate(date1: Date, date2: Date): boolean {
    return (
        new Date(date1).getFullYear() === new Date(date2).getFullYear() &&
        new Date(date1).getMonth() === new Date(date2).getMonth() &&
        new Date(date1).getDate() === new Date(date2).getDate()
    );
}

export function toRoundDate(date: Date | number): Date {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    return date;
}

export function dayInterval(d1: Date | number, d2: Date): number {
    d1 = toRoundDate(d1);
    d2 = toRoundDate(d2);
    return getDiff(d2, d1);
}

export function monthInterval(d1: Date, d2: Date): number {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export function weekInterval(d1: Date, d2: Date): number {
    return Math.ceil(dayInterval(d1, d2) / 7);
}

export function remainingDaysToString(remainingDays: number): string {
    if (Math.abs(remainingDays) > 365) {
        const years = remainingDays / 365;
        const wording = years > 0 ? 'N_YEARS' : 'N_YEARS_AGO';
        return __(wording, { n: Math.floor(Math.abs(years)).toString() });
    }

    if (Math.abs(remainingDays) > 30) {
        const months = remainingDays / 30;
        const wording = months > 0 ? 'N_MONTHS' : 'N_MONTHS_AGO';
        return __(wording, { n: Math.floor(Math.abs(months)).toString() });
    }

    if (remainingDays === -1) return __('TOMORROW');
    if (remainingDays === 0) return __('TODAY');
    if (remainingDays === 1) return __('YESTERDAY');

    if (remainingDays > 1) {
        return __('N_DAYS', { n: Math.floor(Math.abs(remainingDays)).toString() });
    }
    return __('DATE_AGO', { days: Math.floor(Math.abs(remainingDays)).toString() });
}

export function remainingDaysToDateShortString(date: Date) {
    const daysRemaining = date
        ? Math.max(0, Math.round((date.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24))
        : undefined;

    return `${daysRemaining} ${__('DAYS').substring(0, 1).toUpperCase()}`;
}

export function datesIntervalInDaysToString(d1: Date, d2: Date): string {
    const interval = dayInterval(d1, d2);
    if (interval < -1) {
        return __('DATE_IN', { days: Math.floor(Math.abs(interval)).toString() });
    }
    if (interval === -1) return __('DATE_TOMOROW');
    if (interval === 0) return __('DATE_TODAY');
    if (interval === 1) return __('DATE_YESTERDAY');
    return __('DATE_AGO', { days: Math.floor(Math.abs(interval)).toString() });
}

export function datesIntervalToTimeBefore(d1: Date, d2: Date): string {
    const minutes = getDiff(d1, d2, MS_PER_MINUTE);
    return minutes > 59
        ? __('BEFORE_HOURS', { hours: Math.ceil(minutes / 60) })
        : __('BEFORE_MINUTES', { minutes: minutes });
}

export function getDiff(date1: Date, date2: Date, divider: number = MS_PER_DAY): number {
    const _date1 = new Date(date1);
    const _date2 = new Date(date2);
    const diffTime = _date2.getTime() - _date1.getTime();

    return Math.ceil(diffTime / divider);
}

export function getNextSunday(date: Date): Date {
    const nextSunday = new Date(date);

    if (isSunday(nextSunday)) {
        nextSunday.setDate(nextSunday.getDate() + 7);
    } else {
        const nextMonday = getNextMonday(date);
        nextSunday.setDate(nextMonday.getDate() - 1);
    }
    return nextSunday;
}

export function getPrevMonday(date: Date): Date {
    const d = new Date(date);

    while (d.getDay() !== 1) {
        d.setDate(d.getDate() - 1);
    }

    return d;
}

export function setMidnight(_date: Date): Date {
    const date = new Date(_date);
    date.setHours(0, 0, 0, 0);
    date.setDate((_date instanceof Date ? _date : new Date(_date)).getDate());
    return date;
}

export function isoDateToLocalMidnight(dateIsoString: string): Date {
    const [year, month, day] = dateIsoString.split('T')[0].split('-').map(parseInt);
    return new Date(year, month - 1, day, 0, 0);
}

export function isoDateToLocalLatest(dateIsoString: string): Date {
    const [year, month, day] = dateIsoString.split('T')[0].split('-').map(parseInt);
    return new Date(year, month - 1, day, 23, 59, 59);
}

export function weekNumber(d: Date): number {
    const date = new Date(d);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
        1 +
        Math.round(
            ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
        )
    );
}

export function datesOverlap(d1Start: Date, d1End: Date, d2Start: Date, d2End: Date): boolean {
    if (!d2Start || !d2End) {
        console.warn('datesOverlap: d2Start or d2End is undefined');
        return false;
    }
    return (
        Math.max(d1Start.getTime(), d2Start.getTime()) < Math.min(d1End.getTime(), d2End.getTime())
    );
}

export function roundToMinutes(date: Date, minutes: number = 15, method: string = 'round'): Date {
    date = new Date(date);
    if (date.getMinutes() > 60 - minutes / 2) {
        date.setHours(date.getHours() + 1);
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    }
    date.setMinutes((Math[method](date.getMinutes() / minutes) * minutes) % 60);
    date.setSeconds(0);
    return date;
}

export function formatDateRange(startDate: Date, endDate: Date): string {
    const formatToShortMonth = (date: Date) =>
        new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);

    const humanStartDateYear = new Date(startDate).getFullYear();
    const humanEndDateYear = new Date(endDate).getFullYear();
    const humanStartDateMonth = formatToShortMonth(startDate);
    const humanEndDateMonth = formatToShortMonth(endDate);

    if (humanStartDateYear === humanEndDateYear && humanStartDateMonth === humanEndDateMonth) {
        return `${humanStartDateMonth}. ${humanStartDateYear}`;
    }

    if (humanStartDateYear === humanEndDateYear) {
        return `${humanStartDateMonth}. - ${humanEndDateMonth}. ${humanStartDateYear}`;
    }

    return `${humanStartDateMonth}. ${humanStartDateYear} - ${humanEndDateMonth}. ${humanEndDateYear}`;
}

export function roundToPeriodStart(date: Date, period: string): Date {
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    if (period === 'hour') return date;
    date.setHours(0);
    if (period === 'day') return date;
    if (period === 'week') {
        return getPrevMonday(date);
    }
    date.setDate(1);
    if (period === 'month') return date;
    date.setMonth(0);
    return date;
}

export function numberOfDaysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
}

export function numberOfDaysInYear(year: number): number {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
}

export function roundToPeriodEnd(date: Date, period: string): Date {
    date.setMilliseconds(59);
    date.setSeconds(59);
    date.setMinutes(59);
    if (period === 'hour') return date;
    date.setHours(23);
    if (period === 'day') return date;
    if (period === 'week') {
        return getNextSunday(date);
    }
    date.setDate(numberOfDaysInMonth(date));
    if (period === 'month') return date;
    date.setMonth(11);
    return date;
}

export function getPeriodDelta(start: Date, end: Date, period: string): number | undefined {
    switch (period) {
        case 'hour':
            return getDiff(start, end, MS_PER_HOUR);
        case 'day':
            return getDiff(start, end, MS_PER_DAY);
        case 'week':
            return getDiff(start, end, MS_PER_WEEK);
        case 'month':
            return monthInterval(start, end);
        case 'year':
            return end.getFullYear() - start.getFullYear();
    }
}

export function getIntlDateTimeFormat(
    date: string | Date,
    locale: Locale | string,
    fb?: string
): string | undefined {
    try {
        if (typeof date === 'string') {
            if (/^0000/.test(date)) return fb;
            if (isNaN(Date.parse(date))) return fb;
        }

        return new Intl.DateTimeFormat(localeToDateLocale(locale)).format(
            typeof date === 'string' ? new Date(date) : date
        );
    } catch (e) {
        return fb;
    }
}
export function msToHmsString(ms: number): string {
    const hms = msToHms(ms);
    if (hms.h) return __('H_M_S', hms);
    return __('M_S', hms);
}

function immutableDate(date?: Date | number) {
    return new Date(date instanceof Date ? date.getTime() : date || Date.now());
}

function isMonday(date: Date): boolean {
    return date.getDay() === 1;
}

function getNextMonday(date: Date): Date {
    const nextMonday = new Date(date);
    if (isMonday(nextMonday)) {
        nextMonday.setDate(nextMonday.getDate() + 7);
        return nextMonday;
    }

    nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7));

    if (isEqualDate(nextMonday, new Date(date))) {
        nextMonday.setDate(nextMonday.getDate() + 7);
    }

    return nextMonday;
}

function isSunday(date: Date): boolean {
    return date.getDay() === 0;
}

function msToHms(ms: number): { h: number; m: number; s: number } {
    const d = new Date(ms);
    return { h: d.getUTCHours(), m: d.getUTCMinutes(), s: d.getUTCSeconds() };
}

export function htmlTimeToSeconds(time: string) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60;
}

export function dateToTime(date: Date) {
    return `${doubleDigit(date.getHours())}:${doubleDigit(date.getMinutes())}`;
}
