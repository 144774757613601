import styles from './SidePanelNotesHeader.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { FileEnum } from '@he-novation/config/types/file.types';
import { NoteSorter, NoteSorting } from '@he-novation/config/types/note.types';
import { TaskStatus } from '@he-novation/config/types/task.types';
import { Team } from '@he-novation/config/types/team.types';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { downloadLink } from '@he-novation/front-shared/utils/downloadLink';
import { notePaths } from '@he-novation/paths/herawApiPaths';
import { pathWithParams, pdfSummaryLink } from '@he-novation/paths/herawFrontUris';
import __ from '@he-novation/utils/i18n';
import cn from 'classnames';
import { TaskStatusFilter } from './TaskStatusFilter';

import { TeamFilter } from '$components/SidePanel/SidePanelFile/SidePanelNotes/SidePanelNotesHeader/TeamFilter';
import { fileNameUuidAndVersionSelector } from '$redux/content/file/fileSelectors';
import { folderHasAvidSelector } from '$redux/content/folder/folderSelectors';
import { currentProjectUuidSelector } from '$redux/content/projects/projectsSelector';

type SidePanelNotesHeaderProps = {
    fileType: FileEnum;
    sorters: NoteSorter[];
    sorting: NoteSorting;
    setSorting: (sorting: NoteSorting) => void;
    taskStatusFilters: TaskStatus[];
    setTaskStatusFilters: (taskStatusFilters: TaskStatus[]) => void;
    teams: Team[];
    teamFilters: string[];
    setTeamFilters: (teamFilters: string[]) => void;
    castFileUuid?: string;
};

export function SidePanelNotesHeader({
    fileType,
    sorters,
    sorting,
    setSorting,
    taskStatusFilters,
    setTaskStatusFilters,
    teams,
    teamFilters,
    setTeamFilters,
    castFileUuid
}: SidePanelNotesHeaderProps) {
    const { fileName, fileUuid, fileVersion } = useSelector(fileNameUuidAndVersionSelector);
    const { folderHasAvid } = useSelector(folderHasAvidSelector);
    const { currentProjectUuid } = useSelector(currentProjectUuidSelector);
    const downloadActions: ActionType[] = [
        {
            children: __('REACT_EXPORT_COMMENT_PDF'),
            onClick: () => openPDFLink(fileUuid, fileVersion, teamFilters, taskStatusFilters)
        }
    ];

    if (folderHasAvid && fileType === 'video')
        downloadActions.push(
            {
                children: __('REACT_EXPORT_COMMENT_AVID'),
                onClick: () =>
                    openAvidLink(
                        fileUuid,
                        fileVersion,
                        fileName,
                        'lf',
                        teamFilters,
                        taskStatusFilters
                    )
            },
            {
                children: __('REACT_EXPORT_COMMENT_AVID_CRLF'),
                onClick: () =>
                    openAvidLink(
                        fileUuid,
                        fileVersion,
                        fileName,
                        'crlf',
                        teamFilters,
                        taskStatusFilters
                    )
            }
        );

    return (
        <header className={styles.header}>
            <FormField
                className={cn(styles.sort, formStyles.dark)}
                id="note-sorting"
                type="select"
                value={sorting}
                onChange={(e) => setSorting(e.currentTarget.value)}
            >
                {sorters.map((sorter) => (
                    <option key={sorter.value} value={sorter.value}>
                        {sorter.label}
                    </option>
                ))}
            </FormField>
            {!castFileUuid && (
                <div>
                    {currentProjectUuid && (
                        <>
                            <TeamFilter
                                teams={teams}
                                setTeamFilters={setTeamFilters}
                                teamFilters={teamFilters}
                            />
                            <TaskStatusFilter
                                setTaskStatusFilters={setTaskStatusFilters}
                                taskStatusFilters={taskStatusFilters}
                            />
                        </>
                    )}
                    <MultiActionButton
                        direction={[DirectionX.RightInner, DirectionY.Bottom]}
                        actions={downloadActions}
                        icon="download"
                    />
                </div>
            )}
        </header>
    );
}

function openPDFLink(
    fileUuid: string,
    fileVersion: number,
    teamFilters: string[],
    taskFilters: TaskStatus[]
) {
    const url = `?filters=${encodeURIComponent(
        JSON.stringify({
            teamFilters,
            taskFilters
        })
    )}`;
    window.open(pdfSummaryLink(fileUuid, fileVersion) + url);
}

function openAvidLink(
    fileUuid: string,
    fileVersion: number,
    fileName: string,
    lineBreak: 'lf' | 'crlf',
    teamFilters: string[],
    taskFilters: TaskStatus[]
) {
    downloadLink(
        process.env.API_URL +
            pathWithParams(
                notePaths.avidSummary,
                { fileUuid, fileVersion },
                {
                    filters: JSON.stringify({
                        teamFilters,
                        taskFilters
                    }),
                    lineBreak
                }
            ),
        fileName + '.txt',
        true
    );
}
