import { asyncActionProgress, asyncActionSuccess } from '$helpers/asyncAction';
import {
    FETCH,
    WS_EVENT_UPSERT,
    WS_EVENT_DELETE,
    WS_EVENT_DELETE_USER,
    UPDATE,
    APPEND
} from './eventsActions';
import update from 'immutability-helper';
import { mapEvent } from '@he-novation/front-shared/async/event.async';

export const eventsInitialState = {
    isLoading: false,
    isUpdating: false,
    events: []
};

export default (state = eventsInitialState, action) => {
    switch (action.type) {
        case asyncActionProgress(FETCH): {
            return update(state, {
                isLoading: { $set: true }
            });
        }
        case asyncActionSuccess(FETCH): {
            return update(state, {
                isLoading: { $set: false },
                events: {
                    $set: action.events.sort((a, b) => a.startDate - b.startDate)
                }
            });
        }
        case asyncActionProgress(APPEND): {
            return update(state, {
                isUpdating: { $set: true },
                dateRangeUpdating: { $set: action.range }
            });
        }
        case asyncActionSuccess(APPEND): {
            return update(state, {
                isUpdating: { $set: false },
                dateRangeUpdating: { $set: null },
                events: {
                    $push: action.events.sort((a, b) => a.startDate - b.startDate)
                }
            });
        }

        case WS_EVENT_UPSERT: {
            const eventIndex = state.events.findIndex(({ uuid }) => action.event.uuid === uuid);
            const mappedEvent = mapEvent(action.event);
            if (eventIndex > -1) {
                return update(state, {
                    events: { [eventIndex]: { $merge: mappedEvent } }
                });
            }
            return update(state, {
                events: { $push: [mappedEvent] }
            });
        }

        case WS_EVENT_DELETE:
            return update(state, {
                events: {
                    $splice: [[state.events.findIndex(({ uuid }) => action.eventUuid === uuid), 1]]
                }
            });

        case WS_EVENT_DELETE_USER: {
            const eventIndex = state.events.findIndex(({ uuid }) => action.eventUuid === uuid);
            return update(state, {
                events: {
                    [eventIndex]: {
                        users: {
                            $splice: [
                                [
                                    state.events[eventIndex].users.findIndex(
                                        ({ uuid }) => action.userUuid === uuid
                                    ),
                                    1
                                ]
                            ]
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(UPDATE): {
            return update(state, {
                events: {
                    [state.events.findIndex(({ uuid }) => action.event.uuid === uuid)]: {
                        $merge: action.event
                    }
                }
            });
        }
    }

    return state;
};
