import styles from './MultiActionButton.module.css';
import React, { CSSProperties, ReactNode, SyntheticEvent } from 'react';
import { Button, ButtonProps, ButtonTone } from '../Button/Button';
import { AbsoluteContentButton } from '../AbsoluteContentButton/AbsoluteContentButton';
import { Direction, DirectionX, DirectionY } from '../../../utils/getAbsolutePosition';
import classNames from 'classnames';
import { MultiActionButtonList, MultiActionsList } from './MultiActionButtonList';
import { Theme } from '../../../enums';
import { zIndexMenu } from '../../../constants/constants.style';
import { IconProp } from '../../graphics/Icon/Icon';

export type ActionType =
    | (ButtonProps & { separator?: boolean })
    | (MultiActionButtonProps & { separator?: boolean });

export type MultiActionButtonProps = {
    actions: MultiActionsList;
    className?: string | false | null;
    closeOnClickOutside?: boolean;
    closeOnContentClick?: boolean;
    closeOnTriggerClick?: boolean;
    contentClassName?: string;
    contentSizedByTrigger?: boolean;
    delay?: number;
    direction?: Direction;
    disabled?: boolean;
    icon?: SVGElement | IconProp;
    tone?: ButtonTone;
    fireIfSingle?: boolean;
    hasArrow?: boolean;
    iconAfter?: boolean;
    hoverable?: boolean;
    theme?: Theme;
    onClickableClick?: (e: MouseEvent) => any;
    onMouseOver?: (e: SyntheticEvent<unknown, MouseEvent>) => any;
    children?: ReactNode | ReactNode[];
    onClick?: (e: SyntheticEvent<unknown, MouseEvent>) => void;
    style?: CSSProperties;
    contentZIndex?: number;
    id?: string;
    type?: 'button' | 'submit';
};

export function MultiActionButton({
    actions,
    delay,
    children,
    className,
    closeOnContentClick = true,
    closeOnClickOutside = true,
    closeOnTriggerClick = true,
    contentClassName,
    contentSizedByTrigger,
    direction = [DirectionX.Center, DirectionY.Top],
    disabled,
    fireIfSingle,
    icon,
    tone,
    theme,
    hasArrow = false,
    hoverable = false,
    onClickableClick,
    onClick,
    onMouseOver,
    style,
    contentZIndex,
    iconAfter,
    type
}: MultiActionButtonProps) {
    return fireIfSingle && actions.length === 1 && actions[0] ? (
        <Button
            theme={theme}
            tone={tone}
            disabled={disabled}
            icon={icon}
            iconAfter={iconAfter}
            onMouseOver={onMouseOver}
            style={style}
            {...actions[0]}
            className={classNames(styles.multiActionButton, className)}
            onClick={(e) => {
                onClick?.(e);
                if (actions[0]) actions[0].onClick?.(e);
            }}
            type={type}
        >
            {children}
        </Button>
    ) : (
        <AbsoluteContentButton
            delay={delay}
            className={classNames(styles.multiActionButton, className)}
            direction={direction}
            disabled={disabled}
            tagName={Button}
            triggersOnHover={hoverable}
            triggersOnClick={true}
            closeOnClickOutside={closeOnClickOutside}
            closeOnContentClick={closeOnContentClick}
            closeOnTriggerClick={closeOnTriggerClick}
            icon={icon}
            iconAfter={iconAfter}
            tone={tone}
            contentClassName={classNames(contentClassName, hasArrow && 'has-arrow')}
            contentSizedByTrigger={contentSizedByTrigger}
            onMouseOver={onMouseOver}
            onClick={onClick}
            style={style}
            contentZIndex={contentZIndex || zIndexMenu}
            content={
                <MultiActionButtonList
                    onClickableClick={onClickableClick}
                    actions={actions}
                    contentZIndex={contentZIndex}
                />
            }
            type={type}
        >
            {children}
        </AbsoluteContentButton>
    );
}
