export enum ClientLabelType {
    Cast = 'cast',
    Event = 'event',
    Contact = 'contact',
    Item = 'item'
}

export type BaseClientLabel = {
    uid: string;
    name: string | null;
    position: number;
};

export type ClientEventLabel = BaseClientLabel & {
    color: string;
};

export type ClientEventLabelPayload = Omit<ClientEventLabel, 'uid' | 'position'>;
export type ClientEventLabelUpdatePayload = Partial<ClientEventLabel>;

export type ClientItemLabel = BaseClientLabel;

export type ClientItemLabelPayload = Omit<ClientItemLabel, 'uid' | 'position'>;
export type ClientItemLabelUpdatePayload = Partial<ClientItemLabel>;

export type ClientContactLabel = BaseClientLabel;

export type ClientContactLabelPayload = Omit<ClientContactLabel, 'uid' | 'position'>;
export type ClientContactLabelUpdatePayload = Partial<ClientContactLabel>;

export type ClientCastLabel = BaseClientLabel;

export type ClientCastLabelPayload = Omit<ClientCastLabel, 'uid' | 'position'>;
export type ClientCastLabelUpdatePayload = Partial<ClientCastLabelPayload>;

export type ClientLabel<
    T extends
        | ClientLabelType.Event
        | ClientLabelType.Contact
        | ClientLabelType.Item
        | ClientLabelType.Cast
> = T extends ClientLabelType.Event
    ? ClientEventLabel
    : T extends ClientLabelType.Item
    ? ClientItemLabel
    : T extends ClientLabelType.Contact
    ? ClientContactLabel
    : ClientCastLabel;

export type ClientLabelPayload<
    T extends
        | ClientLabelType.Event
        | ClientLabelType.Contact
        | ClientLabelType.Item
        | ClientLabelType.Cast
> = T extends ClientLabelType.Event
    ? ClientEventLabelPayload
    : T extends ClientLabelType.Item
    ? ClientItemLabelPayload
    : T extends ClientLabelType.Contact
    ? ClientContactLabelPayload
    : ClientCastLabelPayload;

export type ClientLabelUpdatePayload<
    T extends
        | ClientLabelType.Event
        | ClientLabelType.Contact
        | ClientLabelType.Item
        | ClientLabelType.Cast
> = T extends ClientLabelType.Event
    ? ClientEventLabelUpdatePayload
    : T extends ClientLabelType.Item
    ? ClientItemLabelUpdatePayload
    : T extends ClientLabelType.Contact
    ? ClientContactLabelUpdatePayload
    : ClientCastLabelUpdatePayload;
