import { Contact, ContactType, GroupPayload } from '@he-novation/config/types/contact.types';
import { ContactSchema } from '@he-novation/config/types/payloads/contact.payload';
import { asyncFolderSharedWith } from '@he-novation/front-shared/async/folder.async';
import { IMPORT_CONTACT_ERROR } from '@he-novation/paths/modals.constants';
import { ThunkDispatch } from 'redux-thunk';
import asyncAction, { asyncActionSuccess } from '../../../helpers/asyncAction';
import { openModal } from '../../route/routeActions';
import { API_CLIENT } from './contactsApi';
import {
    contactAdd,
    contactAddToGroup,
    contactDelete,
    contactDeleteFromGroup,
    contactEdit,
    contactGroupAdd,
    contactGroupDelete,
    contactGroupEdit,
    contactGroupsFetch,
    fetchContacts as _fetchContacts,
    fetchGroup as _fetchGroup,
    importContacts as _importContacts
} from './contactsAsync';

export const CONTACTS = 'CONTACTS';
export const ADD_CONTACT = `${CONTACTS}/ADD_CONTACT`;
export const ADD_GROUP = `${CONTACTS}/ADD_GROUP`;
export const ADD_TO_GROUP = `${CONTACTS}/ADD_TO_GROUP`;
export const DELETE_CONTACT = `${CONTACTS}/DELETE_CONTACT`;
export const DELETE_FROM_GROUP = `${CONTACTS}/DELETE_FROM_GROUP`;
export const DELETE_GROUP = `${CONTACTS}/DELETE_GROUP`;
export const EDIT_CONTACT = `${CONTACTS}/EDIT_CONTACT`;
export const EDIT_GROUP = `${CONTACTS}/EDIT_GROUP`;
export const FETCH = `${CONTACTS}/FETCH`;
export const FETCH_GROUP = `${CONTACTS}/FETCH_GROUP`;
export const FETCH_GROUPS = `${CONTACTS}/FETCH_GROUPS`;
export const IMPORT_CONTACT = `${CONTACTS}/IMPORT_CONTACT`;
export const FETCH_FOLDERS = `${CONTACTS}/FETCH_FOLDER`;
export const MODIFY_ACCESS = `${CONTACTS}/MODIFY_ACCESS`;
export const FETCH_CURRENT_USER_CONTACTS = `${CONTACTS}/FETCH_CURRENT_USER_CONTACTS`;

//folder right setting
export const API_FOLDER_MODIFY = 'api/folder/modify';

type Dispatch = ThunkDispatch<any, any, any>;

export const fetchContacts =
    (success?: (r: ContactType[]) => void) => async (dispatch: Dispatch) => {
        dispatch({ type: FETCH });
        const response = await _fetchContacts();
        dispatch({ type: asyncActionSuccess(FETCH), response });
        if (typeof success === 'function') success(response);
    };

export const fetchUsers = (success) =>
    asyncAction(FETCH, {
        method: 'POST',
        url: `${API_CLIENT}/list`,
        success
    })();

export const fetchGroup = (uuid) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_GROUP });
    const response = await _fetchGroup(uuid);
    dispatch({ type: asyncActionSuccess(FETCH_GROUP), uuid, response });
};

export const fetchGroups = () => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_GROUPS });
    const groups = await contactGroupsFetch();
    dispatch({ type: asyncActionSuccess(FETCH_GROUPS), groups });
};

export const fetchFolders = (userUuid: string) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_FOLDERS });
    const content = await asyncFolderSharedWith(userUuid);
    dispatch({ type: asyncActionSuccess(FETCH_FOLDERS), content, userUuid });
};

export const addContact =
    (payload: ContactSchema, cb?: (r: Contact) => void) => async (dispatch: Dispatch) => {
        dispatch({ type: ADD_CONTACT });
        const contact = await contactAdd(payload);
        dispatch({ type: asyncActionSuccess(ADD_CONTACT), contact });
        cb?.(contact);
    };

export const addGroup =
    (payload: GroupPayload, cb?: (error?: any) => void) => async (dispatch: Dispatch) => {
        dispatch({ type: ADD_GROUP });
        try {
            const { uuid, isClientGroup } = await contactGroupAdd(payload);
            dispatch({ type: asyncActionSuccess(ADD_GROUP), ...payload, uuid, isClientGroup });
            if (typeof cb === 'function') cb();
        } catch (e) {
            if (typeof cb === 'function') cb(e);
        }
    };

export const addToGroup =
    (contactUuids: string[], groupUuid: string) => async (dispatch: Dispatch) => {
        dispatch({ type: ADD_TO_GROUP });
        await contactAddToGroup(contactUuids, groupUuid);
        dispatch({ type: asyncActionSuccess(ADD_TO_GROUP), contactUuids, groupUuid });
    };

export const editGroup =
    (uuid: string, payload: Partial<GroupPayload>, cb?: (error?: any) => void) =>
    async (dispatch: Dispatch) => {
        dispatch({ type: EDIT_GROUP });
        try {
            const updatedContacts = await contactGroupEdit(uuid, payload);
            dispatch({ type: asyncActionSuccess(EDIT_GROUP), uuid, ...payload, updatedContacts });
            cb?.();
        } catch (e) {
            cb?.(e);
        }
    };

export const deleteContact = (uuid: string) => async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_CONTACT });
    await contactDelete(uuid);
    dispatch({ type: asyncActionSuccess(DELETE_CONTACT), uuid });
};

export const deleteGroup = (uuid: string) => async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_GROUP });
    await contactGroupDelete(uuid);
    dispatch({ type: asyncActionSuccess(DELETE_GROUP), uuid });
};

export const deleteFromGroup =
    (contactUuids: string[], groupUuid: string) => async (dispatch: Dispatch) => {
        dispatch({ type: DELETE_FROM_GROUP });
        await contactDeleteFromGroup(contactUuids, groupUuid);
        dispatch({ type: asyncActionSuccess(DELETE_FROM_GROUP), contactUuids, groupUuid });
    };

export const editContact = (uuid: string, contact: ContactSchema) => async (dispatch: Dispatch) => {
    dispatch({ type: EDIT_CONTACT });
    const r = await contactEdit(uuid, contact);
    dispatch({ type: asyncActionSuccess(EDIT_CONTACT), uuid, contact: r });
};

export const importContact = (file: File, cb: () => void) => async (dispatch: Dispatch) => {
    dispatch({ type: IMPORT_CONTACT });
    try {
        const response = await _importContacts({ 'contact-csv': file });
        dispatch({ type: asyncActionSuccess(IMPORT_CONTACT), response });
        dispatch(fetchContacts());
        if (typeof cb === 'function') cb();
    } catch (e) {
        dispatch(openModal(IMPORT_CONTACT_ERROR, { contactErrors: e }));
    }
};

export const SET_CONTACTS = `${CONTACTS}/SET_CONTACTS`;
export function setContacts(contacts: ContactType[]) {
    return {
        type: SET_CONTACTS,
        contacts
    };
}
