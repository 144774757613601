import './HubspotButton.scss';
import React, { useEffect, useState } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';

export const HubspotButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const el = document.getElementById('hubspot-messages-iframe-container');

        if (el) {
            if (isOpen) window.HubSpotConversations.widget.open();
            else window.HubSpotConversations.widget.close();

            el.classList.toggle('is-open', isOpen);
        }
    }, [isOpen]);

    return (
        <Button id="c-hubspot-button" tone={ButtonTone.Dark} onClick={() => setIsOpen(!isOpen)}>
            ?
        </Button>
    );
};
