import styles from './NoteList.module.css';
import React, { useEffect, useState } from 'react';
import type { Note as NoteType } from '@he-novation/config/types/note.types';
import { useOnClickOutside } from '@he-novation/design-system/hooks/useOnClickOutside';
import { useAtom } from 'jotai';
import { selectedNoteAtom } from '../../../../../atoms/note-atoms';

import { Note } from '$components/SidePanel/SidePanelFile/SidePanelNotes/NoteList/Note';

type NoteListProps = {
    items: NoteType[];
    creatingNote: boolean;
};
export function NoteList({ items, creatingNote }: NoteListProps) {
    const [replying, setReplying] = useState<string | null>(null);
    const [editing, setEditing] = useState<string | null>(null);
    const [selectedNote, setSelectedNote] = useAtom(selectedNoteAtom);

    useEffect(() => {
        if (replying) {
            setSelectedNote(replying);
        }
    }, [replying]);

    useEffect(() => {
        if (editing) {
            const note = items.find((item) => {
                return !!item.comments.find((comment) => comment.uuid === editing);
            });
            if (!note) {
                console.warn('Missing noteUuid for editing comment', note);
                return;
            }
            setSelectedNote(note.uuid);
            setReplying(null);
        }
    }, [editing]);

    const ref = useOnClickOutside<HTMLUListElement>(() => {
        if (!replying) {
            setSelectedNote(null);
        }
    }, [replying]);

    return (
        <ul className={styles.list} ref={ref}>
            {items.map((item) => (
                <li
                    key={item.uuid + `${selectedNote || ''}`}
                    onClick={() => {
                        if (!replying) setSelectedNote(item.uuid);
                    }}
                >
                    <Note
                        key={
                            item.uuid +
                            `${selectedNote || ''}-${editing || ''}-${replying || ''}item`
                        }
                        item={item}
                        creatingNote={creatingNote}
                        selected={selectedNote}
                        replying={replying}
                        setReplying={setReplying}
                        editing={editing}
                        setEditing={setEditing}
                    />
                </li>
            ))}
        </ul>
    );
}
