import { ClientState } from '@he-novation/config/types/client.types';
import { compose, pick, pipe, property } from 'lodash/fp';
import { CLIENT } from '../client/clientActions';

import { ReduxState } from '$redux/store';

export const stateSelector: (state: ReduxState) => ClientState = property(CLIENT);

export const clientUuidSelector = pipe(stateSelector, property('uuid'), (clientUuid) => ({
    clientUuid
}));
export const currentClientUuidSelector = compose(
    ({ uuid }) => ({ currentClientUuid: uuid }),
    stateSelector
);

export const clientInfosAsInfosSelector = pipe(
    stateSelector,
    pick(['name', 'created', 'uuid', 'profile']),
    (infos) => ({ infos })
);

export const clientStorageSelector = pipe(stateSelector, property('storage'), (clientStorage) => ({
    clientStorage
}));

export const clientUsersSelector = pipe(stateSelector, property('users'), (clientUsers) => ({
    clientUsers
}));
export const clientGuestsSelector = pipe(stateSelector, property('guests'), (clientGuests) => ({
    clientGuests
}));

export const clientNameSelector = pipe(stateSelector, property('name'), (clientName) => ({
    clientName
}));

export const clientLabelsObjSelector = (type: string) =>
    pipe(stateSelector, property('labels'), property(type), (labels) => ({
        labels
    }));
export const clientLabelsSelector = (type: string) => pipe(stateSelector, (s) => s.labels[type]);

export const translationCreditsSelector = pipe(
    stateSelector,
    pick(['translationCredits', 'translationCreditsTotal'])
);

export const clientPreferencesSelector = pipe(
    stateSelector,
    property('preferences'),
    (clientPreferences) => ({ clientPreferences })
);
