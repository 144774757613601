import React from 'react';
import { MultiActionButtonList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { useOnClickOutside } from '@he-novation/design-system/hooks/useOnClickOutside';

import { useAbsoluteMenu } from '$hooks/useAbsoluteMenu';

export const ABSOLUTE_MENU_ID = 'absolute-menu';
export function AbsoluteMenu() {
    const { content, position, visible, close } = useAbsoluteMenu();
    const ref = useOnClickOutside<HTMLDivElement>(() => {
        if (content) {
            close();
        }
    }, [content]);

    return (
        <div
            id={ABSOLUTE_MENU_ID}
            ref={ref}
            style={{
                position: 'fixed',
                left: position[0],
                top: position[1],
                zIndex: 100,
                visibility: content && visible ? 'visible' : 'hidden'
            }}
        >
            {content && (
                <MultiActionButtonList
                    actions={content}
                    onClickableClick={() => {
                        close();
                    }}
                />
            )}
        </div>
    );
}
