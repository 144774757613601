import styles from './HeaderWrapper.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { colorAverage } from '@he-novation/config/utils/colors';
import cn from 'classnames';

import combineSelectors from '$helpers/combineSelectors';
import { useClientStyle } from '$hooks/useClientStyle';
import { usePanel } from '$hooks/usePanel';
import { fileUuidSelector } from '$redux/content/file/fileSelectors';
import { currentProjectSelector } from '$redux/content/projects/projectsSelector';
import { routeSelector } from '$redux/route/routeSelectors';

export function HeaderWrapper() {
    const { panel } = usePanel();

    const { currentProject, fileUuid, route } = useSelector(
        combineSelectors(currentProjectSelector(), fileUuidSelector, routeSelector)
    );
    const { clientStyle } = useClientStyle();

    const isPlayer = (route as string).startsWith('/player/');

    const extraCSS: React.CSSProperties = {};
    let background = '';
    let backgroundColor = '';
    if (isPlayer) {
        background = 'var(--color-bg-4)';
        extraCSS['opacity'] = '1';
    } else {
        const backgroundUrl =
            currentProject?.uuid && !fileUuid
                ? currentProject.backgroundUrl || clientStyle?.background?.url
                : null;
        backgroundColor =
            currentProject?.uuid && !fileUuid ? currentProject.color || clientStyle?.primary : null;

        background = backgroundUrl
            ? `url('${backgroundUrl}') center  top / cover no-repeat`
            : 'var(--color-bg-2)';

        if (backgroundColor) {
            extraCSS[
                '--background-gradient'
            ] = `linear-gradient(to right, ${backgroundColor}, rgb(from ${backgroundColor} r g b / 64%)`;
            if (colorAverage(backgroundColor) > 127) {
                extraCSS['--header-text-color'] = 'black';
                extraCSS['--header-text-color-faded'] = 'rgba(0, 0, 0, 0.5)';
                extraCSS['--header-img-invert-filter'] = 'invert(1)';
            }
        }
    }

    return (
        <header
            id="header-wrapper"
            className={styles.headerWrapper}
            style={
                {
                    '--color-primary': currentProject?.color || undefined,
                    background: background,
                    ...extraCSS
                } as React.CSSProperties
            }
        >
            <div id="header" className={styles.header} />
            <div
                id="sub-header"
                className={cn(
                    styles.subHeader,
                    panel ? styles.hasOpenPanel + ' has-open-panel' : undefined,
                    !isPlayer && styles.subProject
                )}
            />
        </header>
    );
}
