import { UserFile } from '@he-novation/config/types/user.types';
import { atom } from 'jotai';

export type ClientStyle = {
    logo?: UserFile | null;
    background?: UserFile | null;
    primary?: string;
};

export const clientStyleAtom = atom<ClientStyle | null>(null);
