import styles from './SidePanelFile.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { Title } from '@he-novation/design-system/components/text/Title/Title';
import { Tabs } from '@he-novation/design-system/components/widgets/Tabs/Tabs';
import { __ } from '@he-novation/design-system/utils/i18n';

import { SidePanelNotes } from '$components/SidePanel/SidePanelFile/SidePanelNotes/SidePanelNotes';
import { SidePanelSubtitles } from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/SidePanelSubtitles';
import { useTranslate } from '$hooks/useTranslate';
import { fileTypeSelector } from '$redux/content/file/fileSelectors';

type SidePanelNotesProps = {
    payload?: {
        castFileUuid?: string;
    };
};
const SidePanelFile = ({ payload: { castFileUuid } = {} }: SidePanelNotesProps) => {
    const { fileType } = useSelector(fileTypeSelector);

    const { t } = useTranslate();

    return (
        <div className={styles.fileSidePanel}>
            {fileType === 'video' && !castFileUuid ? (
                <Tabs
                    className={styles.tabs}
                    tabs={[
                        {
                            value: 'side-panel-tab-notes',
                            label: t('player.Notes')
                        },
                        {
                            value: 'side-panel-tab-subs',
                            label: t('common.Subtitles')
                        }
                    ]}
                >
                    <SidePanelNotes fileType={fileType} />
                    <SidePanelSubtitles />
                </Tabs>
            ) : (
                <>
                    <Title tag="h2" type="tab" className={styles.title} icon="speech_bubbles">
                        {t('player.Notes')}
                    </Title>
                    <SidePanelNotes fileType={fileType} />
                </>
            )}
        </div>
    );
};

export default SidePanelFile;
