import { TaskColumn, TaskStatus } from '../types/task.types';
import { ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR } from '../utils/acl';
// order of roles is important
export enum ROLES {
    MANAGER = 'PROJECT_MANAGER',
    PROVIDER = 'PROJECT_PROVIDER',
    REPORTER = 'PROJECT_REPORTER',
    SPECTATOR = 'PROJECT_SPECTATOR'
}

export const TEAM_ADMIN = 'ADMINISTRATORS';
export const TEAM_CAST = '__CAST';

export const TEAM_COLORS = [
    '#FFFFFF',
    '#E45B3B',
    '#00B3BB',
    '#ECEFF5',
    '#BB1F20',
    '#3b8750',
    '#3b5b87',
    '#6f3b87',
    '#c7450e',
    '#879b05'
];

export const isAdmin = (role: ROLES) => role === ROLES.MANAGER;
export const isAdminTeam = (team: string) => team === TEAM_ADMIN;

export const taskColumnToTaskStatus = (column: TaskColumn): TaskStatus => {
    switch (column) {
        case TaskColumn.ToDo:
            return TaskStatus.TO_DO;
        case TaskColumn.InProgress:
            return TaskStatus.IN_PROGRESS;
        case TaskColumn.ToValidate:
            return TaskStatus.TO_VALIDATE;
        case TaskColumn.Done:
            return TaskStatus.DONE;
    }
    throw new Error('Unknown column, deleted task not handled');
};

export const taskStatusToClass = (status: TaskStatus) => {
    switch (status) {
        case TaskStatus.TO_DO:
            return 'status-to-do';
        case TaskStatus.IN_PROGRESS:
            return 'status-in-progress';
        case TaskStatus.TO_VALIDATE:
            return 'status-to-validate';
        case TaskStatus.DONE:
            return 'status-done';
    }
};

export const taskStatusToColor = (status: TaskStatus) => {
    switch (status) {
        case TaskStatus.TO_DO:
            return '#ff335e';
        case TaskStatus.IN_PROGRESS:
            return '#da73e0';
        case TaskStatus.TO_VALIDATE:
            return '#51cef6';
        case TaskStatus.DONE:
            return '#49f281';
    }
};

export const taskStatusToI18n = (status: TaskStatus) => {
    switch (status) {
        case TaskStatus.IN_PROGRESS:
            return 'In progress';
        case TaskStatus.TO_VALIDATE:
            return 'To validate';
        case TaskStatus.DONE:
            return 'Done';
    }
    return 'To do';
};

export enum PROJECT_STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    ARCHIVED = 'ARCHIVED'
}

export const projectRoleToFolderRole = (projectRole: ROLES) => {
    switch (projectRole) {
        case ROLES.MANAGER:
            return ROLE_MANAGER;
        case ROLES.PROVIDER:
            return ROLE_PROVIDER;
        case ROLES.REPORTER:
            return ROLE_REPORTER;
        case ROLES.SPECTATOR:
            return ROLE_SPECTATOR;
    }
};

export const folderRoleToProjectRole = (folderRole: string) => {
    switch (folderRole) {
        case ROLE_MANAGER:
            return ROLES.MANAGER;
        case ROLE_PROVIDER:
            return ROLES.PROVIDER;
        case ROLE_REPORTER:
            return ROLES.REPORTER;
        case ROLE_SPECTATOR:
            return ROLES.SPECTATOR;
    }
};
