import { ClientState, ClientStorage } from '@he-novation/config/types/client.types';
import update from 'immutability-helper';
import {
    CLIENT_FETCH,
    CLIENT_GUESTS_FETCH,
    CLIENT_LABEL_ADD,
    CLIENT_LABEL_DELETE,
    CLIENT_LABEL_UPDATE,
    CLIENT_LABELS_FETCH,
    CLIENT_LABELS_SET,
    CLIENT_LICENSE_CANCEL,
    CLIENT_STORAGE_SET,
    CLIENT_UPDATE_PASSWORD,
    CLIENT_UPDATE_PROFILE,
    CLIENT_USER_ADD,
    CLIENT_USER_DELETE,
    CLIENT_USER_EDIT,
    CLIENT_USER_PROFILE_PIC_SET,
    CLIENT_USER_TRANSFER,
    CLIENT_USERS_FETCH
} from '../client/clientActions';

import { asyncActionSuccess } from '$helpers/asyncAction';
import { FETCH, FETCH_ACCOUNT } from '$redux/user/userActions';

export const clientInitialState: ClientState = {
    labels: {
        event: [],
        cast: [],
        contact: [],
        item: []
    },
    users: [],
    guests: [],
    preferences: {},
    translationCredits: 0,
    translationCreditsTotal: 0
};

const mapAddOrEditPayloadToUser = (data) => {
    const { clientRights, storage, ...user } = data;
    return {
        ...user,
        ...user.profile,
        clientRights,
        storage_available: storage
    };
};

export default (state: ClientState = clientInitialState, action: any = {}) => {
    switch (action.type) {
        case asyncActionSuccess(FETCH):
            return update(state, {
                $merge: {
                    name: action.user.client_name
                }
            });

        case asyncActionSuccess(CLIENT_FETCH):
            return update(state, {
                $merge: action.client
            });
        case CLIENT_STORAGE_SET:
            return update(state, {
                storage: {
                    $merge: action.storage as ClientStorage
                }
            });

        case asyncActionSuccess(CLIENT_USERS_FETCH):
            return update(state, {
                users: {
                    $set: action.users
                }
            });

        case asyncActionSuccess(CLIENT_USER_ADD): {
            return update(state, {
                users: {
                    $push: [action.user]
                }
            });
        }

        case asyncActionSuccess(CLIENT_USER_DELETE): {
            const i = state.users.findIndex(({ uuid }) => uuid === action.uuid);
            if (i < 0) return state;
            return update(state, {
                users: {
                    [i]: { $merge: mapAddOrEditPayloadToUser(action.data) }
                }
            });
        }

        case asyncActionSuccess(CLIENT_USER_EDIT): {
            const i = state.users.findIndex(({ uuid }) => uuid === action.uuid);
            if (i < 0) return state;

            const _update: any = {
                users: {
                    [i]: { $merge: mapAddOrEditPayloadToUser(action.data) }
                }
            };
            if (action.clientTranslationCredits)
                _update.translationCredits = { $set: action.clientTranslationCredits };

            return update(state, _update);
        }

        case asyncActionSuccess(CLIENT_GUESTS_FETCH):
            return update(state, {
                guests: {
                    $set: action.guests
                }
            });

        case asyncActionSuccess(CLIENT_LICENSE_CANCEL): {
            const i = state.guests.findIndex(
                ({ licenseUuid }) => licenseUuid === action.licenseUuid
            );
            if (i < 0) return state;
            return update(state, {
                guests: {
                    $splice: [[i, 1]]
                }
            });
        }

        case asyncActionSuccess(CLIENT_USER_TRANSFER):
            return update(state, {
                users: {
                    $splice: [[state.users.findIndex(({ uuid }) => uuid === action.userUuid), 1]]
                }
            });

        case asyncActionSuccess(CLIENT_LABELS_FETCH):
            return update(state, {
                labels: {
                    [action.labelType]: {
                        $set: action.labels
                    }
                }
            });
        case asyncActionSuccess(CLIENT_LABEL_ADD):
            return update(state, {
                labels: {
                    [action.labelType]: {
                        $push: [action.label]
                    }
                }
            });

        case asyncActionSuccess(CLIENT_LABEL_UPDATE): {
            const i = state.labels[action.labelType]?.findIndex((l) => l.uid === action.uid);
            if (i === -1) return state;
            return update(state, {
                labels: {
                    [action.labelType]: {
                        [i]: { $merge: action.payload }
                    }
                }
            });
        }
        case asyncActionSuccess(CLIENT_LABEL_DELETE):
            return update(state, {
                labels: {
                    [action.labelType]: {
                        $set: state.labels[action.labelType].filter((l) => l.uid !== action.uid)
                    }
                }
            });

        case CLIENT_LABELS_SET:
            return update(state, {
                labels: {
                    [action.labelType]: {
                        $set: action.labels
                    }
                }
            });

        case CLIENT_USER_PROFILE_PIC_SET: {
            const userIndex = state.users.findIndex(({ uuid }) => uuid === action.userUuid);
            if (userIndex < 0) return state;
            return update(state, {
                users: {
                    [userIndex]: {
                        picture: {
                            $set: action.picture
                        }
                    }
                }
            });
        }
    }

    return state;
};
