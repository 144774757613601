import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { FormEvent, useCallback } from 'react';
import { ButtonTone } from '@he-novation/design-system/components/buttons/BottomButtons/BottomButtons';
import { __ } from '@he-novation/design-system/utils/i18n';
import {
    asyncCastFolderCreate,
    asyncCastFolderUpdate
} from '@he-novation/front-shared/async/cast.async';

import { FormCastFolder } from '$components/form/FormCastFolder';
import ModalBottomButtons from '$components/modal/ModalBottomButtons/ModalBottomButtons';
import { ModalContent } from '$components/modal/ModalContent/ModalContent';
import ModalHeader from '$components/modal/ModalHeader/ModalHeader';
import { useTranslate } from '$hooks/useTranslate';

type ModalFormCastFolderProps = {
    payload: {
        castUid: string;
        castFolderUuid?: string;
        castParentFolderUuid?: string;
        cb?: (data: any) => void;
        data?: { uuid: string };
    };
    closeModal: () => void;
};

export function ModalFormCastFolder({
    payload: { data, castUid, castFolderUuid },
    closeModal
}: ModalFormCastFolderProps) {
    const submit = useCallback(async (e: FormEvent, payload: { name: string }) => {
        e.preventDefault();
        if (data?.uuid) {
            await asyncCastFolderUpdate(castUid, data.uuid, payload.name);
        } else {
            await asyncCastFolderCreate(castUid, castFolderUuid, payload.name);
            closeModal();
        }
    }, []);

    const { t } = useTranslate();

    const formId = 'form-cast-folder';

    return (
        <>
            <ModalContent className={formStyles.light}>
                <ModalHeader title={__(data?.uuid ? 'CAST_FOLDER_UPDATE' : 'CAST_FOLDER_CREATE')} />
                <FormCastFolder formId={formId} submit={submit} />
            </ModalContent>
            <ModalBottomButtons
                buttons={[
                    {
                        children: t('common.Cancel'),
                        onClick: () => closeModal(),
                        tone: ButtonTone.Outlined
                    },
                    {
                        type: 'submit',
                        form: formId,
                        children: t('common.Save'),
                        tone: ButtonTone.Primary
                    }
                ]}
            />
        </>
    );
}

export default ModalFormCastFolder;
