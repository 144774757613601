import { CastsState } from '@he-novation/config/types/cast.types';
import update from 'immutability-helper';
import type { Action } from 'redux';

import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    CASTS_CREATE,
    CASTS_DELETE,
    CASTS_EDIT,
    CASTS_FETCH
} from '$redux/content/casts/castsActions';
import { LoadedStatus } from '$types/enums';

export const castsInitialState: CastsState = {
    status: LoadedStatus.NotLoaded,
    list: [],
    projectList: [],
    folders: {}
};

export const castsReducer = (state: CastsState = castsInitialState, action: Action & any) => {
    switch (action.type) {
        case CASTS_FETCH:
            return update(state, {
                status: { $set: LoadedStatus.Pending }
            });

        case asyncActionSuccess(CASTS_FETCH):
            return update(state, {
                projectUuid: { $set: action.projectUuid },
                status: { $set: LoadedStatus.Loaded },
                list: { $set: action.casts },
                projectList: { $set: action.projectCasts || [] }
            });

        case asyncActionSuccess(CASTS_CREATE):
            return update(state, {
                list: {
                    $push: [
                        {
                            ...action.cast,
                            created: new Date(),
                            views: 0,
                            expired: false,
                            folders: 0
                        }
                    ]
                }
            });

        case asyncActionSuccess(CASTS_EDIT): {
            const now = new Date().getTime();
            const expired = action.cast.expires
                ? now > new Date(action.cast.expires).getTime()
                : false;
            const key = action.projectUuid ? 'projectList' : 'list';
            return update(state, {
                [key]: {
                    [state[key].findIndex((c) => c.uid === action.cast.uid)]: {
                        $merge: {
                            ...action.cast,
                            created: new Date(action.cast.created),
                            expired
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(CASTS_DELETE): {
            const key = action.projectUuid ? 'projectList' : 'list';
            const castIndex = state[key].findIndex((c) => c.uid === action.castUid);

            return update(state, {
                [key]: {
                    $splice: [[castIndex, 1]]
                }
            });
        }
    }

    return state;
};
