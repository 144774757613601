import {
    CONTACT_GROUPS,
    FILE_PANEL,
    FILTERS_PANEL,
    FOLDER_SETTINGS,
    ITEM_KITS
} from '$constants/constants.sidePanels';
import SidePanelGroups from './SidePanelGroups/SidePanelGroups';
import SidePanelFile from '$components/SidePanel/SidePanelFile/SidePanelFile';
import { SidePanelItemKits } from '../SidePanel/SidePanelItemKits/SidePanelItemKits';
import { SidePanelFolder } from './SidePanelFolderSettings/SidePanelFolder';
import { SidePanelFilters } from '$components/SidePanel/SidePanelFilters/SidePanelFilters';

export const sidePanels = {
    [CONTACT_GROUPS]: {
        Component: SidePanelGroups
    },
    [FILE_PANEL]: {
        Component: SidePanelFile
    },
    [FILTERS_PANEL]: {
        Component: SidePanelFilters
    },
    [FOLDER_SETTINGS]: {
        Component: SidePanelFolder
    },
    [ITEM_KITS]: {
        Component: SidePanelItemKits
    }
};

export default sidePanels;
