import './ItemPicker.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import { FormField } from '../../FormField';
import { Group, Option } from '../ReactSelect/ReactSelect';
import { AvatarItem, AvatarSize } from '../../../../avatars/Avatar/AvatarItem';
import { ReactSelectLabel } from '../ReactSelectLabel/ReactSelectLabel';
import Icon from '@he-novation/icons';
import { Tooltip } from '../../../../widgets/Tooltip/Tooltip';

export type Event = {
    endDate: Date;
    startDate: Date;
    uuid: string;
};
export type Item = {
    uuid: string;
    name: string;
    pictureAsset?: { uuid: string; key?: string; url: string; urlExpires?: Date } | null;
    events: Event[];
    labels?: { name: string }[];
};

export type ItemKit = {
    uuid: string;
    name: string;
    items: Item[];
};

function datesOverlap(d1Start: Date, d1End: Date, d2Start: Date, d2End: Date): boolean {
    return (
        Math.max(d1Start.getTime(), d2Start.getTime()) < Math.min(d1End.getTime(), d2End.getTime())
    );
}

type ItemPickerProps = FieldComponentProps & {
    items: Item[];
    itemKits?: ItemKit[];
    canRemove?: (item: Item) => boolean;
    startDate?: Date;
    endDate?: Date;
    renderList?: (props: { uuids: string[] }) => React.ReactNode;
};

const BusyTooltip = ({
    startDate,
    endDate,
    events
}: {
    startDate?: Date;
    endDate?: Date;
    events: Event[];
}) => {
    const busy = !!(
        startDate &&
        endDate &&
        events &&
        events.find((e) => datesOverlap(startDate, endDate, e.startDate, e.endDate))
    );

    const dates = useMemo(
        () =>
            events
                .map(
                    (e) => `${e.startDate.toLocaleString()}
                            &nbsp;-&nbsp;
                            ${e.endDate.toLocaleString()}`
                )
                .filter((e, i, arr) => arr.indexOf(e) === i),
        [events]
    );

    return busy ? (
        <Tooltip
            content={
                <ul>
                    {dates.map((date) => (
                        <li key={date} dangerouslySetInnerHTML={{ __html: date }} />
                    ))}
                </ul>
            }
            className="busy"
        />
    ) : null;
};

const itemToOption = (i: Item, startDate?: Date, endDate?: Date): Option => {
    return {
        label: (
            <ReactSelectLabel>
                <AvatarItem size={AvatarSize.Small} {...i} picture={i.pictureAsset?.url} /> {i.name}
                <BusyTooltip startDate={startDate} endDate={endDate} events={i.events || []} />
            </ReactSelectLabel>
        ),
        searchValue: i.name + ' ' + (i.labels || []).map((l) => l.name).join(' '),
        value: i.uuid
    };
};

const itemKitToGroup = (group: ItemKit): Group => {
    return {
        label: (
            <ReactSelectLabel>
                <Icon icon="shapes" />
                {group.name}
            </ReactSelectLabel>
        ),
        searchValue: group.name,
        value: group.uuid,
        items: group.items.map((i) => itemToOption(i))
    };
};

export function ItemPicker({
    formId,
    name,
    theme,
    items,
    itemKits = [],
    value: _value,
    canRemove,
    startDate,
    endDate,
    menuIsOpen,
    renderList,
    onChange,
    placeholder
}: ItemPickerProps) {
    const options = items
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((i) => itemToOption(i, startDate, endDate));
    const groups = itemKits.sort((a, b) => (a.name < b.name ? -1 : 1)).map(itemKitToGroup);
    const [value, setValue] = useState<string[]>();
    useEffect(() => {
        setValue(_value || []);
    }, [_value]);

    return (
        <FormField
            theme={theme}
            formId={formId}
            name={name}
            placeholder={placeholder}
            id={`${formId}-${name}-select`}
            type="react-select"
            isMulti
            menuIsOpen={menuIsOpen}
            menuClassName="is-item-picker"
            hideSelectedOptions
            isSearchable
            coerceType={'array'}
            filterBy={'searchValue'}
            components={{
                ClearIndicator: (): null => null,
                MultiValue: (): null => null
            }}
            onChange={(e, v) => {
                setValue(v);
                onChange?.(e, v);
            }}
            options={options}
            groups={groups}
            after={
                renderList ? (
                    renderList({ uuids: value || [] })
                ) : (
                    <ul className={'deletables'}>
                        {value?.map((uuid) => {
                            const item = items.find((i) => i.uuid === uuid);
                            if (!item) return null;
                            const isRemovable = !canRemove || canRemove(item);
                            return (
                                <li key={uuid}>
                                    <AvatarItem
                                        size={AvatarSize.Small}
                                        {...item}
                                        picture={item.pictureAsset?.url}
                                        onClick={
                                            isRemovable
                                                ? (e) => {
                                                      if (
                                                          (
                                                              e.target as HTMLDivElement
                                                          ).className.includes('tooltip')
                                                      )
                                                          return;
                                                      setValue(
                                                          value.filter((uuid) => uuid !== item.uuid)
                                                      );
                                                  }
                                                : undefined
                                        }
                                        tooltip
                                    />

                                    <BusyTooltip
                                        startDate={startDate}
                                        endDate={endDate}
                                        events={item.events || []}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                )
            }
            value={value}
        />
    );
}
