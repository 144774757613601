import React, { CSSProperties } from 'react';
import { ICONS, iconToString } from '@he-novation/config/constants/icons';
import { ICONS_URL } from '../../../configuration';
import cn from 'classnames';

type IconString = (typeof ICONS)[number];

type IconOptions = {
    name: IconString;
    stroke?: string;
    fill?: string;
    circle?: boolean;
};

export type IconProp =
    | IconString
    | IconOptions
    | [IconString, IconString]
    | [IconOptions]
    | [IconOptions, IconOptions];

type IconProps = {
    icon: IconProp;
    stroke?: string;
    fill?: string;
    className?: string;
    iconsUrl?: string;
    style?: CSSProperties;
};

type NormalizedIcons = [IconOptions] | [IconOptions, IconOptions];

function iconToIconOptions(icon: IconString | IconOptions, stroke?: string, fill?: string) {
    if (typeof icon === 'string') return { name: icon, stroke, fill };
    return icon;
}

function normalizeIcons(icons: IconProps['icon'], stroke?: string, fill?: string): NormalizedIcons {
    if (!Array.isArray(icons)) {
        return [iconToIconOptions(icons, stroke, fill)];
    }
    return icons.map((icon: IconString | IconOptions) =>
        iconToIconOptions(icon, stroke, fill)
    ) as NormalizedIcons;
}

export function Icon({ icon, stroke = 'white', fill, className, iconsUrl, style }: IconProps) {
    const icons = normalizeIcons(icon, stroke, fill);
    let iconString = iconToString(icons[0]);
    if (icons[1]) iconString += '--' + iconToString(icons[1]);
    return (
        <img
            style={style}
            className={cn('svg-icon', className)}
            src={`${iconsUrl || ICONS_URL}/${iconString}`}
            alt=""
        />
    );
}
