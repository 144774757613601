import { FolderStateFile, FullFolder } from '@he-novation/config/types/folder.types';
import {
    WSFolderEventAssetReady,
    WSFolderEventSourceAssetType,
    WSFolderEventTranscodingFinished,
    WSFolderEventTranscodingPending,
    WSFolderEventTranscodingProgress
} from '@he-novation/config/types/websockets/folder.ws.types';
import { __ } from '@he-novation/design-system/utils/i18n';
import { fileCopy, fileMove } from '@he-novation/front-shared/async/file.async';
import {
    asyncFolderCopyTree,
    asyncFolderMembersFetch,
    asyncFolderRestore,
    asyncFolderUpdate,
    folderMove,
    grantAccess as _grantAccess,
    modifyAccess,
    revokeAccess as _revokeAccess
} from '@he-novation/front-shared/async/folder.async';
import { FrontFolderContentGhost } from '@he-novation/front-shared/types/folder.front-types';
import { userSettingsLink } from '@he-novation/paths/herawFrontUris';
import { cloneDeep } from 'lodash/fp';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';
import { openFeedbackModal, set } from '$redux/route/routeActions';
import { FOLDER } from '$redux/storeNames';
import { openToast } from '$redux/ui/uiActions';

export const FETCH_FOLDER = `${FOLDER}/FETCH`;
export const FETCH_CONTENT = `${FOLDER}/FETCH_CONTENT`;
export const FETCH_FILE_REVIEWERS = `${FOLDER}/FETCH_FILE_REVIEWERS`;
export const FETCH_FILE_INFO = `${FOLDER}/FETCH_FILE_INFO`;
export const FOLDER_ACCESS_MODIFY = `${FOLDER}/ACCESS_MODIFY`;
export const FOLDER_ITEMS_COPY = `${FOLDER}/ITEMS_COPY`;
export const FOLDER_ITEMS_PASTE = `${FOLDER}/ITEMS_PASTE`;

export const FOLDER_CONTENT_GHOST_CREATE = `${FOLDER}/CONTENT_GHOST_CREATE`;
export const FOLDER_CONTENT_GHOST_DELETE = `${FOLDER}/CONTENT_GHOST_DELETE`;
export const FOLDER_LOADED = `${FOLDER}/LOADED`;

export const FOLDER_RENAME = `${FOLDER}/RENAME`;
export const FOLDER_TREE_COPY = `${FOLDER}/TREE_COPY`;
export const FOLDER_RESTORE = `${FOLDER}/FOLDER_RESTORE`;
export const FOLDER_UPDATE = `${FOLDER}/FOLDER_UPDATE`;
export const GRANT_ACCESS = `${FOLDER}/GRANT_ACCESS`;
export const REVOKE_ACCESS = `${FOLDER}/REVOKE_ACCESS`;
export const DELETE_FILE = `${FOLDER}/DELETE_FILE`;
export const FETCH_PLUGINS_ACCESS = `${FOLDER}/FETCH_PLUGINS_ACCESS`;
export const FOLDER_MEMBERS_FETCH = `${FOLDER}/MEMBERS_FETCH`;
export const SET_FOLDER_FILE = `${FOLDER}/SET_FOLDER_FILE`;
export const SETTINGS_SET = `${FOLDER}/SETTINGS_SET`;
export const USER_METADATA_SET = `${FOLDER}/USER_METADATA_SET`;
export const UPDATE_UUID = `${FOLDER}/UPDATE_UUID`;
export const UPLOAD_FINISHED = `${FOLDER}/UPLOAD_FINISHED`;
export const WS_FILE_ADD = `${WS_PREFIX_IN}${FOLDER}/FILE_ADD`;
export const WS_FILE_CONVERT_TO_VERSION = `${WS_PREFIX_IN}${FOLDER}/FILE_CONVERT_TO_VERSION`;
export const WS_FOLDER_ADD = `${WS_PREFIX_IN}${FOLDER}/FOLDER_ADD`;
export const WS_FOLDER_DELETE = `${WS_PREFIX_IN}${FOLDER}/FOLDER_DELETE`;
export const WS_SET_FOLDER = `${WS_PREFIX_IN}/FOLDER_SET`;
export const WS_SET_FOLDER_FILE = `${WS_PREFIX_IN}${SET_FOLDER_FILE}`;
export const WS_FILE_UPLOAD_SOURCE_READY = `${WS_PREFIX_IN}${FOLDER}/FILE_UPLOAD_SOURCE_READY`;
export const WS_UPLOAD_ASSET_UPDATE = `${WS_PREFIX_IN}${FOLDER}/UPLOAD_ASSET_UPDATE`;
export const WS_UPLOAD_ASSET_FINISH = `${WS_PREFIX_IN}${FOLDER}/SET_UPLOAD_ASSET_FINISH`;
export const WS_DELETE_FILE = `${WS_PREFIX_IN}${FOLDER}/DELETE_FILE`;
export const WS_FILE_CAST = `${WS_PREFIX_IN}${FOLDER}/FILE_CAST`;
export const WS_FILE_CAST_DELETE = `${WS_PREFIX_IN}${FOLDER}/FILE_CAST_DELETE`;
export const WS_FOLDER_CAST = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_CAST`;
export const WS_FOLDER_CAST_DELETE = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_CAST_DELETE`;

export const WS_FOLDER_MEMBERS_ADD = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_MEMBERS_ADD`;

export const WS_FOLDER_MEMBERS_REMOVE = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_MEMBERS_REMOVE`;

export const WS_FOLDER_ASSET_UPLOAD_PROGRESS = `${WS_PREFIX_IN}${FOLDER}/ASSET_UPLOAD_PROGRESS`;

export const WS_FOLDER_ASSET_READY = `${WS_PREFIX_IN}${FOLDER}/ASSET_READY`;
export const WS_FOLDER_SOURCE_ASSET_TYPE = `${WS_PREFIX_IN}${FOLDER}/SOURCE_ASSET_TYPE`;
export const WS_FOLDER_TRANSCODING_PENDING = `${WS_PREFIX_IN}${FOLDER}/TRANSCODING_PENDING`;
export const WS_FOLDER_TRANSCODING_PROGRESS = `${WS_PREFIX_IN}${FOLDER}/TRANSCODING_PROGRESS`;
export const WS_FOLDER_TRANSCODING_FINISHED = `${WS_PREFIX_IN}${FOLDER}/TRANSCODING_FINISHED`;

export const WS_FILE_VERSION_DELETE = `${WS_PREFIX_IN}${FOLDER}/FILE_VERSION_DELETE`;
export const WS_FILE_VERSION_RESTORE = `${WS_PREFIX_IN}${FOLDER}/FILE_VERSION_RESTORE`;

export const folderRename =
    (folderUuid: string, name: string, cb: (e: Error | null, folder?: FullFolder) => void) =>
    async (dispatch) => {
        dispatch({ type: FOLDER_RENAME });
        try {
            const folder = await asyncFolderUpdate(folderUuid, { name });
            dispatch({ type: asyncActionSuccess(FOLDER_RENAME), folder });
            cb(null, folder);
        } catch (e) {
            cb(e);
        }
    };

export const folderRestore = (folderUuid) => async (dispatch) => {
    dispatch({ type: FOLDER_RESTORE });
    try {
        await asyncFolderRestore(folderUuid);
        dispatch({ type: asyncActionSuccess(FOLDER_RESTORE), folderUuid });
    } catch (e) {
        if (e?.message) dispatch(openFeedbackModal(__(e.message)));
    }
};

export const deleteFile = ({ file }) => ({
    type: DELETE_FILE,
    file
});

export const wsDeleteFile = ({ file }) => ({
    type: WS_DELETE_FILE,
    file
});

export const folderMembersFetch = (folderUuid: string, pending?: boolean) => async (dispatch) => {
    dispatch({ type: FOLDER_MEMBERS_FETCH });
    const members = await asyncFolderMembersFetch(folderUuid, pending);
    dispatch({ type: asyncActionSuccess(FOLDER_MEMBERS_FETCH), folderUuid, members });
};

export const grantFolderAccess =
    (
        folderUuid: string,
        members: {
            uuid: string;
            role: string;
            canDownload: boolean;
            canExport: boolean;
        }[],
        message?: string | null
    ) =>
    async (dispatch) => {
        dispatch({ type: GRANT_ACCESS });
        try {
            await _grantAccess(folderUuid, members, message);
            dispatch({
                type: asyncActionSuccess(GRANT_ACCESS),
                members,
                folderUuid
            });
        } catch (e) {
            console.error(e);
            if (e.error?.message === 'ERR_LICENSES_INSUFFICIENT') {
                dispatch(
                    openToast({
                        content: __(e.error.message),
                        autoCloseAfterMs: 6000,
                        buttons: [
                            {
                                children: __('MAIN_MENU_MY_ACCOUNT'),
                                onClick: () => dispatch(set(userSettingsLink('profile')))
                            }
                        ]
                    })
                );
            }
            dispatch({ type: asyncActionError(GRANT_ACCESS) });
        }
    };

export const folderAccessModify =
    (folderUuid, userUuid, { role, canDownload }) =>
    async (dispatch) => {
        dispatch({ type: FOLDER_ACCESS_MODIFY });

        await modifyAccess(folderUuid, userUuid, {
            role,
            canDownload
        });

        dispatch({
            type: asyncActionSuccess(FOLDER_ACCESS_MODIFY),
            folderUuid,
            userUuid,
            role,
            canDownload
        });
    };

export const folderItemsCopy = (data: any, cut?: boolean) => ({
    type: FOLDER_ITEMS_COPY,
    data,
    cut
});

export const folderTreeCopy = (data: any, cut?: boolean) => ({
    type: FOLDER_TREE_COPY,
    data,
    cut
});

export const folderPaste =
    (copy: any, folderUuid: string) => async (dispatch: (obj: Record<string, unknown>) => void) => {
        if (!copy) return;

        const isCut = copy.cut;
        const data = cloneDeep(copy.data);

        dispatch({
            type: FOLDER_ITEMS_PASTE,
            items: data,
            folderUuid,
            cut: copy.cut
        });

        const foldersToCopy = data.filter((f) => f.type === 'folder');
        let filesToCopy: FolderStateFile[] = [];
        if (copy.type === 'tree') {
            await Promise.all(
                foldersToCopy.map((f) => {
                    return asyncFolderCopyTree(f.uuid, folderUuid, {
                        renameIfExists: true
                    });
                })
            );
        } else {
            filesToCopy = data.filter((f) => f.type === 'file');
            let fileExists = false;
            if (copy.cut) {
                await Promise.all(
                    filesToCopy.map(async (f) => {
                        try {
                            await fileMove(f.uuid, folderUuid);
                        } catch (e) {
                            fileExists = true;
                            if (e?.error?.message === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                            return { ...f, error: e?.error?.message };
                        }
                    })
                );
                await Promise.all(
                    foldersToCopy.map((f) =>
                        folderMove(isCut ? f.uuid : f.uuid.replace('-copy', ''), folderUuid)
                    )
                );
            } else {
                // split filesToCopy in batches of 5 files
                const fileBatches: FolderStateFile[][] = [];
                for (const file of filesToCopy) {
                    if (
                        fileBatches.length === 0 ||
                        fileBatches[fileBatches.length - 1].length === 5
                    ) {
                        fileBatches.push([]);
                    }
                    fileBatches[fileBatches.length - 1].push(file);
                }
                for (const batch of fileBatches) {
                    const r = await Promise.all(
                        batch.map(async (f) => {
                            try {
                                return await fileCopy(
                                    f.uuid.replace(/-copy$/, ''),
                                    f.version,
                                    folderUuid
                                );
                            } catch (e) {
                                return { ...f, error: e?.error?.message };
                            }
                        })
                    );
                    batch.forEach((item, i) => {
                        if (!r[i].error) dispatch(updateUuid(item.uuid, r[i].uuid));
                        else {
                            if (r[i].error === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                            dispatch(deleteFile({ file: { uuid: item.uuid } }));
                        }
                    });
                }

                if (fileExists) dispatch(openFeedbackModal(__('ERR_FILE_ALREADY_EXISTS')));
            }
        }

        dispatch({
            type: asyncActionSuccess(FOLDER_ITEMS_PASTE)
        });
    };

export const moveToFolder = (items, folderUuid) => async (dispatch) => {
    let fileExists = false;
    await Promise.all(
        items.map(async (item) => {
            if (item.type === 'file') {
                try {
                    await fileMove(item.uuid, folderUuid);
                } catch (e) {
                    if (e?.error?.message === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                }
            } else {
                await folderMove(item.uuid, folderUuid);
            }
        })
    );

    if (fileExists) dispatch(openFeedbackModal(__('ERR_FILE_ALREADY_EXISTS')));
};

export const updateUuid = (oldUuid: string, newUuid: string) => ({
    type: UPDATE_UUID,
    oldUuid,
    newUuid
});

export const revokeAccess = (folderUuid: string, userUuid: string) => async (dispatch) => {
    dispatch({ type: REVOKE_ACCESS });
    await _revokeAccess(folderUuid, userUuid);
    dispatch({ type: asyncActionSuccess(REVOKE_ACCESS), folderUuid, userUuid });
};

export const wsFileUploadSourceReady = (data) => ({
    type: WS_FILE_UPLOAD_SOURCE_READY,
    data
});

export const wsAssetUploadUpdate = (data) => ({
    type: WS_UPLOAD_ASSET_UPDATE,
    data
});

export const wsAssetUploadFinished = (data) => ({
    type: WS_UPLOAD_ASSET_FINISH,
    data
});

export const uploadFinished = (name, folderUuid) => ({
    type: UPLOAD_FINISHED,
    folderUuid,
    name
});

export const wsFolderAssetUploadProgress = ({ assetUuid, fileUuid, progress }) => ({
    type: WS_FOLDER_ASSET_UPLOAD_PROGRESS,
    assetUuid,
    fileUuid,
    progress
});
export const wsFolderSetSourceAssetType = ({ type, ...data }: WSFolderEventSourceAssetType) => ({
    type: WS_FOLDER_SOURCE_ASSET_TYPE,
    ...data
});
export const wsFolderAssetReady = ({ type, ...data }: WSFolderEventAssetReady) => ({
    type: WS_FOLDER_ASSET_READY,
    ...data
});

export const wsFolderTranscodingPending = ({ type, ...data }: WSFolderEventTranscodingPending) => ({
    type: WS_FOLDER_TRANSCODING_PENDING,
    ...data
});

export const wsFolderTranscodingProgress = ({
    type,
    ...data
}: WSFolderEventTranscodingProgress) => ({
    type: WS_FOLDER_TRANSCODING_PROGRESS,
    ...data
});

export const wsFolderTranscodingFinished = ({
    type,
    ...data
}: WSFolderEventTranscodingFinished) => ({
    type: WS_FOLDER_TRANSCODING_FINISHED,
    ...data
});

export function folderContentGhostCreate(ghost: FrontFolderContentGhost) {
    return {
        type: FOLDER_CONTENT_GHOST_CREATE,
        ghost
    };
}
export function folderContentGhostDelete(ghostUuid: string) {
    return {
        type: FOLDER_CONTENT_GHOST_DELETE,
        uuid: ghostUuid
    };
}
export const folderSocketActions = {
    sioFileUploadAssetUpdate: wsAssetUploadUpdate,
    sioFileUploadAssetFinished: wsAssetUploadFinished,
    sioFileUploadSourceReady: wsFileUploadSourceReady,
    sioFileAdd: ({ file, copying }) => ({
        type: WS_FILE_ADD,
        file,
        copying
    }),
    sioFileDelete: wsDeleteFile,
    sioFileSet: (data) => ({
        type: WS_SET_FOLDER_FILE,
        ...data
    }),
    sioFolderSet: (data) => ({
        type: WS_SET_FOLDER,
        ...data
    }),
    sioFolderAdd: (data) => ({
        type: WS_FOLDER_ADD,
        data
    }),
    sioFolderDelete: (data) => ({
        type: WS_FOLDER_DELETE,
        data
    }),
    sioFileCast: (data) => ({
        type: WS_FILE_CAST,
        data
    }),
    sioFileCastDelete: (data) => ({
        type: WS_FILE_CAST_DELETE,
        data
    }),
    sioFolderCast: (data) => ({
        type: WS_FOLDER_CAST,
        data
    }),
    sioFolderCastDelete: (data) => ({
        type: WS_FOLDER_CAST_DELETE,
        data
    }),
    sioFolderMembersAdd: ({ members }) => ({
        type: WS_FOLDER_MEMBERS_ADD,
        members
    }),
    sioFolderMembersRemove: ({ uuids }) => ({
        type: WS_FOLDER_MEMBERS_REMOVE,
        uuids
    }),
    sioFileVersionDelete: ({ fileUuid, status, deletedVersion, newFileVersion }) => ({
        type: WS_FILE_VERSION_DELETE,
        fileUuid,
        deletedVersion,
        newFileVersion,
        status
    }),
    sioFileVersionRestore: ({ fileUuid, restoredVersion, newFileVersion }) => ({
        type: WS_FILE_VERSION_RESTORE,
        fileUuid,
        restoredVersion,
        newFileVersion
    })
};
