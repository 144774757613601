import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import type { Folder as ContentToUpload } from '@he-novation/design-system/components/form/DropUpload/DropUpload';
import { fetchStorage } from '@he-novation/front-shared/async/user.async';
import { CONTACT_ADMIN } from '@he-novation/paths/modals.constants';
import { v4 as uuid } from 'uuid';

import { Uploader } from '$helpers/Uploader';
import { UploadCallbacks, UploaderState } from '$helpers/Uploader.types';
import { uploadTree } from '$helpers/uploadTree';
import { useTranslate } from '$hooks/useTranslate';
import { openFeedbackModal, openModal } from '$redux/route/routeActions';
import { clientNameSelector, userInfosSelector } from '$redux/user/userSelectors';

export function useUploader() {
    const [state, setState] = useState<UploaderState>({
        uploads: [],
        pending: [],
        finished: [],
        errors: []
    });
    const dispatch = useDispatch();
    const { clientName } = useSelector(clientNameSelector);
    const uploader = Uploader;
    const { userInfos } = useSelector(userInfosSelector);

    useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (uploader.uploads.length) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        uploader.register(setState);
        return () => {
            uploader.unregister(setState);
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);
    const { t } = useTranslate();

    const checkStorage = useCallback(
        async (clientName: string, folderUuid: string, size: number) => {
            const { available } = await fetchStorage(folderUuid);
            if (available !== -1 && size > available) {
                dispatch(
                    openFeedbackModal(
                        t('settings.No storage left, please contact an administrator'),
                        0,
                        {
                            extraButtons:
                                clientName === 'private'
                                    ? undefined
                                    : [
                                          {
                                              onClick: () => dispatch(openModal(CONTACT_ADMIN)),
                                              children: t('settings.Contact administrator'),
                                              tone: ButtonTone.Primary
                                          }
                                      ]
                        }
                    )
                );
                return false;
            }
            return true;
        },
        []
    );

    return {
        ...state,
        upload: async (
            file: File,
            folder: { uuid: string; name: string; shared?: boolean | number },
            { fileUuid }: UploadCallbacks & { fileUuid?: string; message?: string } = {}
        ) => {
            if (!(await checkStorage(clientName, folder.uuid, file.size!))) return;
            uploader.resetInvalidFiles();

            return await uploader.upload(userInfos, {
                uploadGroup: uuid(),
                file,
                folder,
                uploadIndex: 0,
                uploadsTotal: 1,
                parentFileUuid: fileUuid
            });
        },
        uploadTree: async (
            clientName: string,
            userUuid: string,
            rootFolder: {
                uuid: string;
                name: string;
                shared: boolean | number;
                metadata: object;
                triggers: any;
            },
            contentToUpload: ContentToUpload,
            hasPluginFiletree: boolean,
            preferences: object = {}
        ) => {
            if (!(await checkStorage(clientName, rootFolder.uuid, contentToUpload.size!))) return;
            uploader.resetInvalidFiles();

            return await uploadTree(
                userInfos,
                userUuid,
                rootFolder,
                { ...contentToUpload, uuid: rootFolder.uuid, name: rootFolder.name, path: '/' },
                hasPluginFiletree,
                preferences
            );
        }
    };
}
