import Icon from '@he-novation/icons';
import classNames from 'classnames';
import { GenericFormFieldTypes } from 'generic-form';
import type { SyntheticEvent } from 'react';
import React from 'react';
import { ModularFormField } from 'react-modular-forms';
import { ExtractProps } from '../../../utils/extractProps';
import { FormField } from '../FormField/FormField';
import styles from './CopyField.module.css';

const focus = (input: HTMLInputElement) => {
    input.focus();
    input.selectionStart = 0;
    input.selectionEnd = input.value.length;
};

const copy = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    focus((e.currentTarget.parentNode as HTMLElement).getElementsByTagName('input')[0]);
    document.execCommand('copy');
};

type CopyFieldProps = Partial<ExtractProps<typeof ModularFormField>> & {
    value?: string | null;
    readOnly?: boolean;
    className?: string;
    Component?: React.ComponentType;
    formId?: string;
    name?: string;
    id: string;
};

export const CopyField: React.FC<CopyFieldProps> = ({
    value,
    readOnly = true,
    className,
    id,
    name,
    formId,
    ...rest
}) => (
    <FormField
        id={id}
        formId={formId || `${id}-form`}
        name={name || id}
        className={classNames(styles.copyField, 'c-copy-field', className)}
        onFocus={(e: SyntheticEvent<HTMLInputElement>) => focus(e.currentTarget)}
        type={GenericFormFieldTypes.TEXT}
        value={value}
        readOnly={readOnly}
        {...rest}
    >
        <button className="icon" onClick={copy}>
            <Icon icon="copy" />
        </button>
    </FormField>
);
