import styles from './Accordion.module.css';
import React, { useEffect, useState } from 'react';
import { Icon, IconProp } from '@he-novation/design-system/components/graphics/Icon/Icon';
import cn from 'classnames';

interface Props {
    title: React.ReactNode;
    children: React.ReactNode;
    icon?: IconProp;
    className?: string;
    contentClassName?: string;
    onToggle?: (toggled: boolean) => void;
    isOpen?: boolean;
}

const Accordion = ({
    title,
    children,
    icon,
    className,
    contentClassName,
    onToggle,
    isOpen = false
}: Props) => {
    const [_isOpen, toggleIsOpen] = useState(isOpen);
    useEffect(() => {
        if (isOpen !== _isOpen) toggleIsOpen(isOpen);
    }, [isOpen]);

    return (
        <article className={cn(styles.accordion, className, { 'is-open': _isOpen })}>
            <button
                role={'heading'}
                className={cn(styles.header, 'c-accordion-header')}
                onClick={(e) => {
                    e.preventDefault();
                    toggleIsOpen(!_isOpen);
                    if (typeof onToggle === 'function') onToggle(!_isOpen);
                }}
            >
                <div className={styles.accordionHeaderChild}>
                    {icon && <Icon icon={icon} stroke="white" />}
                    {title}
                </div>
                <Icon icon="chevron" stroke="white" />
            </button>
            {_isOpen && (
                <div className={cn(styles.content, 'c-accordion-content', contentClassName)}>
                    {children}
                </div>
            )}
        </article>
    );
};

export default Accordion;
