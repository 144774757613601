import React from 'react';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';

type ModalProps = {
    children?: any;
    className?: string;
    disableClickOutside?: boolean;
    onKeyEscape: (callback: () => void) => void;
    toggleKeyboardListeners: (toggle: boolean) => void;
    close: () => void;
};

type ModalState = {
    disableOverlay?: boolean;
    isOpen: boolean;
    modalLeaving: boolean;
    showContainer: boolean;
    showModal: boolean;
    showOverlay: boolean;
};

class Modal extends React.Component<ModalProps, ModalState> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: !!this.props.children,
            showContainer: !!this.props.children,
            showOverlay:
                this.props.children &&
                this.props.children.props &&
                this.props.children.props.payload
                    ? this.props.children.props.payload.disableOverlay
                    : false,
            showModal: !!this.props.children,
            modalLeaving: false
        };
    }

    componentDidMount() {
        this.props.onKeyEscape(this.onClose);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.isOpen && this.props.children && !prevProps.children) {
            this.onOpen();
        }

        if (
            (prevState.isOpen && !this.state.isOpen) ||
            (this.state.isOpen && !this.props.children && prevProps.children)
        )
            this.onClose();
    }

    onOpen = () => {
        if (this.props.className !== 'public-file') this.props.toggleKeyboardListeners(false);
        this.setState(
            {
                isOpen: true,
                showContainer: true,
                disableOverlay:
                    this.props.children &&
                    this.props.children.props &&
                    this.props.children.props.payload
                        ? this.props.children.props.payload.disableOverlay
                        : false
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        showOverlay: true,
                        showModal: true
                    });
                }, 1);
            }
        );
    };

    onClose = () => {
        this.props.toggleKeyboardListeners(true);
        this.setState(
            {
                showOverlay: false,
                modalLeaving: true
            },
            () => {
                setTimeout(() => {
                    this.setState(
                        {
                            showModal: false,
                            modalLeaving: false,
                            showContainer: false,
                            isOpen: false,
                            disableOverlay: false
                        },
                        () => {
                            this.props.close();
                        }
                    );
                }, 300);
            }
        );
    };

    render() {
        const { children } = this.props;
        return this.state.isOpen ? (
            <ModalWrapper
                className={this.props.className}
                disableOverlay={this.state.disableOverlay}
                isVisible={this.state.isOpen}
                isModalVisible={this.state.showModal}
                isModalLeaving={this.state.modalLeaving}
                isTop={this.props?.children?.props?.payload?.alignTop}
                onClickOutside={this.props.disableClickOutside ? undefined : this.onClose}
            >
                {children || <div className={'c-empty-modal'} />}
            </ModalWrapper>
        ) : null;
    }
}

export default Modal;
