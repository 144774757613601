import update from 'immutability-helper';
import { SET_SIZE } from './configActions';
import isSmallScreen from '../../helpers/isSmallScreen';
import { asyncActionSuccess } from '$helpers/asyncAction';
import { FETCH_FILE_VIEW, FETCH_FOLDER_VIEW } from '$redux/content/contentActions';
import { FETCH_ACCOUNT } from '$redux/user/userActions';
import { CLIENT_FETCH } from '$redux/client/clientActions';
import { FETCH_PROJECT } from '$redux/content/projects/projectsActions';
import { FETCH_FOLDER } from '../content/folder/folderActions';

/* eslint-disable */
export const initialState = {
    locale: typeof LOCALE !== 'undefined' ? LOCALE : 'fr',
    hasLocale: false,
    isFullscreen: false,
    origin: window.location.origin,
    activeBranding: 'private'
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SIZE:
            return {
                ...state,
                width: action.width,
                height: action.height,
                isSmallScreen: isSmallScreen(action.width, action.height)
            };

        case asyncActionSuccess(FETCH_PROJECT):
            if (action.project?.client_name)
                return update(state, {
                    activeBranding: { $set: action.project.client_name }
                });
            return state;

        case asyncActionSuccess(CLIENT_FETCH):
            return update(state, {
                activeBranding: { $set: action.client.name }
            });

        case asyncActionSuccess(FETCH_ACCOUNT):
            return update(state, {
                activeBranding: { $set: action.account.client_name },
                isTeamsApp: { $set: action.account.isTeamsApp }
            });
        case asyncActionSuccess(FETCH_FOLDER_VIEW):
        case asyncActionSuccess(FETCH_FILE_VIEW):
            return update(state, {
                $merge: action.configState
            });
        case asyncActionSuccess(FETCH_FOLDER):
            return update(state, {
                activeBranding: { $set: action.response.client_name }
            });

        default:
            return state;
    }
};
/* eslint-enable */
