import { AreaSelectionState } from '@he-novation/config/types/area-selection.types';
import { CastsState } from '@he-novation/config/types/cast.types';
import { ClientState } from '@he-novation/config/types/client.types';
import { CompaniesState } from '@he-novation/config/types/company.types';
import { ContactsState } from '@he-novation/config/types/contact.types';
import { EventsState } from '@he-novation/config/types/event.types';
import { FileState } from '@he-novation/config/types/file.types';
import { ItemsState } from '@he-novation/config/types/item.types';
import { NoteState } from '@he-novation/config/types/note.types';
import { ProjectsState } from '@he-novation/config/types/project.types';
import { UserState } from '@he-novation/config/types/user.types';
import { FolderState } from '@he-novation/front-shared/types/folder.front-types';
// use deprecated createStore to avoid Date errors (mutability)
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { CLIENT } from './client/clientActions';
import clientReducer, { clientInitialState } from './client/clientReducer';
import { CONFIG } from './config/configActions';
import configReducer from './config/configReducer';
import { CONTENT } from './content/contentActions';
import contentReducer from './content/contentReducer';
import { ROUTE, setRoutes } from './route/routeActions';
import routeReducer from './route/routeReducer';
import { UI } from './ui/uiActions';
import uiReducer from './ui/uiReducer';
import { USER } from './user/userActions';
import userReducer from './user/userReducer';

import { REDUX_AREA_SELECTION } from '$redux/content/areaSelection/areaSelectionActions';
import { areaSelectionInitialState } from '$redux/content/areaSelection/areaSelectionReducer';
import { CASTS } from '$redux/content/casts/castsActions';
import { castsInitialState } from '$redux/content/casts/castsReducer';
import { COMPANIES } from '$redux/content/companies/companiesActions';
import { companiesInitialState } from '$redux/content/companies/companiesReducer';
import { CONTACTS } from '$redux/content/contacts/contactsActions';
import { contactsInitialState } from '$redux/content/contacts/contactsReducer';
import { EVENTS } from '$redux/content/events/eventsActions';
import { eventsInitialState } from '$redux/content/events/eventsReducer';
import { FILE } from '$redux/content/file/fileActions';
import { fileInitialState } from '$redux/content/file/fileReducer';
import { folderInitialState } from '$redux/content/folder/folderReducer';
import { ITEMS } from '$redux/content/items/itemsActions';
import { itemsInitialState } from '$redux/content/items/itemsReducer';
import { PROJECTS } from '$redux/content/projects/projectsActions';
import { projectsInitialState } from '$redux/content/projects/projectsReducer';
import { TEAMS } from '$redux/content/teams/teamsActions';
import { teamsInitialState } from '$redux/content/teams/teamsReducer';
import { i18nInitialState, i18nReducer, i18nReducerName } from '$redux/i18n/i18nReducer';
import { I18nState } from '$redux/i18n/i18nTypes';
import { FOLDER } from '$redux/storeNames';
export type ActionType = {
    type: string;
    [key: string]: unknown;
};
export let lastAction: ActionType;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [
    thunk,
    () => (next) => (action: ActionType) => {
        lastAction = action;
        next(action);
    }
];

const store = createStore(
    combineReducers({
        [CLIENT]: clientReducer,
        [CONFIG]: configReducer,
        [CONTENT]: contentReducer,
        [i18nReducerName]: i18nReducer,
        [ROUTE]: routeReducer,
        [USER]: userReducer,
        [UI]: uiReducer
    }),
    {
        [CLIENT]: clientInitialState,
        [CONTENT]: {
            [CASTS]: castsInitialState,
            [COMPANIES]: companiesInitialState,
            [CONTACTS]: contactsInitialState,
            [EVENTS]: eventsInitialState,
            [FILE]: fileInitialState,
            [FOLDER]: folderInitialState,
            [ITEMS]: itemsInitialState,
            [PROJECTS]: projectsInitialState,
            [TEAMS]: teamsInitialState,
            [REDUX_AREA_SELECTION]: areaSelectionInitialState
        },
        [i18nReducerName]: i18nInitialState
    },
    composeEnhancers(applyMiddleware(...middlewares))
);
window.addEventListener('DOMContentLoaded', () => {
    let sBrowser: string;
    const sUsrAg = window.navigator.userAgent;

    if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = 'Mozilla Firefox';
    } else if (sUsrAg.indexOf('Opera') > -1) {
        sBrowser = 'Opera';
    } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = 'Microsoft Internet Explorer';
    } else if (sUsrAg.indexOf('Edge') > -1) {
        sBrowser = 'Microsoft Edge';
    } else if (sUsrAg.indexOf('Chrome') > -1) {
        sBrowser = 'Google Chrome or Chromium';
    } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = 'Apple Safari';
    } else {
        sBrowser = 'unknown';
    }
    store.dispatch(setRoutes(window.location.href));
});

export type ContentState = {
    activity: any;
    areaSelection: AreaSelectionState;
    casts: CastsState;
    companies: CompaniesState;
    contacts: ContactsState;
    events: EventsState;
    file: FileState;
    folder: FolderState;
    items: ItemsState;
    note: NoteState;
    projects: ProjectsState;
    teams: any;
    user: UserState;
};
export type ReduxState = {
    content: ContentState;
    client: ClientState;
    i18n: I18nState;
};

export default store;
