import { FEEDBACK } from '@he-novation/paths/modals.constants';

import { ModalFeedbackPayload } from '$components/modal/modals/ModalFeedback/ModalFeedback';

export const ROUTE = 'ROUTE';
export const SET = `${ROUTE}/SET`;
export const SET_ROUTES = `${ROUTE}/SET_ROUTES`;
export const CLOSE_MODAL = `${ROUTE}/CLOSE_MODAL`;
export const OPEN_MODAL = `${ROUTE}/OPEN_MODAL`;
export const MODAL_PAYLOAD_UPDATE = `${ROUTE}/MODAL_PAYLOAD_UPDATE`;
export const SET_FILTERS = `${ROUTE}/SET_FILTERS`;
export const SET_FILTER = `${ROUTE}/SET_FILTER`;
export const SAVE_QUERY_PARAMS = `${ROUTE}/SAVE_QUERY_PARAMS`;
export const LOAD_QUERY_PARAMS = `${ROUTE}/LOAD_QUERY_PARAMS`;
export const RESET_QUERY_PARAMS = `${ROUTE}/RESET_QUERY_PARAMS`;

export const resetQueryParams = () => ({
    type: RESET_QUERY_PARAMS
});

export const set = (route, silent = false, replace?: boolean) => ({
    type: SET,
    route,
    silent,
    replace
});

export const setRoutes = (routes) => ({
    type: SET_ROUTES,
    routes
});

export const closeModal = () => ({
    type: CLOSE_MODAL
});

export const openModal = (
    modal,
    modalPayload?: Record<string, unknown> | null,
    extraParams?: Record<string, string | number | boolean> | null,
    silent = true
) => ({
    type: OPEN_MODAL,
    modal,
    modalPayload,
    extraParams,
    silent
});

export const openFeedbackModal = (
    message: string,
    timeout = 3000,
    payload?: Omit<ModalFeedbackPayload, 'timeout' | 'message'>
) =>
    openModal(FEEDBACK, {
        message,
        timeout,
        disableOverlay: true,
        alignTop: true,
        ...payload
    });

export const modalPayloadUpdate = ({ modal, payload }) => ({
    type: MODAL_PAYLOAD_UPDATE,
    modal,
    payload
});

export const setFilters = (filters) => ({
    type: SET_FILTERS,
    filters
});

export const setFilter = (filterName, filterValue) => ({
    type: SET_FILTER,
    filterName,
    filterValue
});

export const saveQueryParams = (routeName, queryParamsToSave) => ({
    type: SAVE_QUERY_PARAMS,
    routeName,
    queryParamsToSave
});

export const loadQueryParams = (routeName) => ({
    type: LOAD_QUERY_PARAMS,
    routeName
});
