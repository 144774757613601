import { useEffect } from 'react';
import { UserFileType } from '@he-novation/config/types/user.types';
import { fetchClientStyles } from '@he-novation/front-shared/async/client.async';
import { asyncUserFilesFetch } from '@he-novation/front-shared/async/userFile.async';
import { useAtom } from 'jotai/index';
import { ClientStyle, clientStyleAtom } from '../atoms/client-atoms';

type ClientStyleHook = {
    clientStyle: ClientStyle | null;
    setClientStyle: (clientStyle: ClientStyle | null) => void;
};

export function useClientStyle(): ClientStyleHook {
    const [clientStyle, setClientStyle] = useAtom(clientStyleAtom);

    useEffect(() => {
        if (!clientStyle) {
            const clientLogoFetch = asyncUserFilesFetch(UserFileType.CLIENT_LOGO).then(
                (userFiles) => userFiles[0]
            );

            const clientBackgroundFetch = asyncUserFilesFetch(UserFileType.CLIENT_BACKGROUND).then(
                (userFiles) => userFiles[0]
            );

            const clientColorFetch = fetchClientStyles().then((styles) => styles.primary);

            Promise.all([clientLogoFetch, clientBackgroundFetch, clientColorFetch]).then(
                ([logo, background, primary]) => {
                    setClientStyle({
                        logo,
                        background,
                        primary
                    });
                }
            );
        }
    }, [clientStyle]);

    return { clientStyle, setClientStyle };
}
