import React, { useCallback, useState } from 'react';
import { ContactType, GroupWithContacts } from '@he-novation/config/types/contact.types';
import { FieldComponentProps } from 'react-modular-forms';
import { FormField } from '../../FormField';
import Icon from '@he-novation/icons';
import { ReactSelectLabel } from '../ReactSelectLabel/ReactSelectLabel';
import { ContactOption } from './ContactOption';
import { EmailChipsList } from '../../../../lists/EmailChipsList/EmailChipsList';

export type SelectedContact = ContactType | { email: string; userUuid?: string };

export type SelectedContactsListProps = {
    selected: SelectedContact[];
    onUpdate: (contacts: SelectedContact[]) => void;
};

export type ContactPickerProps = FieldComponentProps & {
    contacts: ContactType[];
    contactGroups?: GroupWithContacts[];
    canCreate?: boolean;
    onCreateFilter?: (newContact: SelectedContact) => boolean;
    ListComponent?: React.ComponentType<SelectedContactsListProps>;
};

export function ContactPicker({
    formId,
    name,
    canCreate,
    contacts,
    contactGroups = [],
    onChange,
    onCreateFilter = (_: SelectedContact) => true,
    ListComponent = EmailChipsList
}: ContactPickerProps) {
    const [selectedContacts, setSelectedContacts] = useState<SelectedContact[]>([]);

    const options = contactsToOptions(contacts, selectedContacts);

    const groups = contactGroups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(
            (group) =>
                ({
                    label: (
                        <ReactSelectLabel size="little">
                            <Icon icon="users" />
                            {group.name}
                        </ReactSelectLabel>
                    ),
                    searchValue: group.name,
                    value: group.uuid,
                    items: contactsToOptions(group.contacts, selectedContacts)
                } as ContactGroupOption)
        );

    const updateContacts = useCallback(
        (newSelectedContacts: SelectedContact[]) => {
            if (selectedContacts !== newSelectedContacts && onChange) {
                onChange(newSelectedContacts);
            }
            setSelectedContacts(newSelectedContacts);
        },
        [selectedContacts]
    );

    return (
        <FormField
            formId={formId}
            name={name}
            id={`${formId}-${name}-select`}
            type="react-select"
            isMulti
            menuClassName="is-contact-picker"
            hideSelectedOptions
            isSearchable
            creatable={canCreate}
            onChange={(e, emails: string[]) => {
                const newSelectedContacts = [
                    ...selectedContacts,
                    ...emails
                        .map((email) => contacts.find((c) => c.email === email))
                        .filter((c) => c !== undefined)
                ];
                updateContacts(newSelectedContacts);
            }}
            onCreateOption={(value: string) => {
                const newContact = { email: value };

                if (onCreateFilter(newContact)) {
                    const newSelectedContacts = [...selectedContacts, newContact];
                    updateContacts(newSelectedContacts);
                }
            }}
            coerceType={'array'}
            value={selectedContacts.map((c) => c.email)}
            filterBy={'searchValue'}
            components={{
                ClearIndicator: (): null => null,
                MultiValue: (_: any): null => null
            }}
            options={options}
            groups={groups}
            after={<ListComponent selected={selectedContacts} onUpdate={updateContacts} />}
        />
    );
}

type ContactGroupOption = ContactOption & {
    items: ContactOption[];
};

type ContactOption = {
    searchValue: string;
    label: React.ReactNode;
    value: string;
};

function contactsToOptions(
    contacts: ContactType[],
    selectedContacts: Partial<ContactType>[]
): ContactOption[] {
    return contacts
        .filter((c) => selectedContacts.find((sc) => sc.email === c.email) === undefined)
        .sort((a, b) => a.email.localeCompare(b.email))
        .map((contact) => ({
            searchValue: contact.email,
            label: <ContactOption contact={contact} key={`contact-option-${contact.email}`} />,
            value: contact.email
        }));
}
