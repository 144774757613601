import { pathWithParams } from './herawApiPaths';

export const SIGNUP = `/:lang/sign-up/:type/:plan`;
export const SIGNIN = `/:lang/sign-in`;
export const RESET_PASSWORD = `/:lang/password-reset`;

export const ADMITTANCE = `/:lang/admittance`;

export const signUpLink = (lang: string, type: string, plan?: string) =>
    process.env.APP_URL + pathWithParams(SIGNUP, { lang, type, plan });
export const signInLink = (
    lang = 'fr',
    query?: { email?: string; redirect?: string; error?: string }
) => process.env.APP_URL + pathWithParams(SIGNIN, { lang }, query);
export const resetPasswordLink = (lang = 'fr') =>
    process.env.APP_URL + pathWithParams(RESET_PASSWORD, { lang });
export const admittanceLink = (lang = 'fr', clientName: string, folderUuid: string) =>
    process.env.APP_URL + pathWithParams(ADMITTANCE, { lang }, { clientName, folderUuid });
