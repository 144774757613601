import {
    Client,
    ClientDomain,
    ClientOIDC,
    ClientUser
} from '@he-novation/config/types/client.types';
import {
    ClientBody,
    ClientBodyUpdate,
    ClientDomainCreateBody,
    ClientDomainSpecificSchema,
    ClientMemberDeleteBody,
    ClientMemberSpecificSchema,
    ClientProfileSchema,
    ClientSpecificSchema,
    ClientUserPasswordUpdateBody,
    ClientUserUpdateBody
} from '@he-novation/config/types/payloads/client.payload';
import { UserUuidSchema } from '@he-novation/config/types/payloads/user.payload';
import paths, { clientPaths, pathWithParams } from '@he-novation/paths/herawApiPaths';
import herawApiPaths from '@he-novation/paths/herawApiPaths';
import omit from 'lodash/omit';
import { apiFetch } from './apiFetch';

export const clientProfileUpdate = async (body: ClientProfileSchema) =>
    apiFetch(paths.client.profile, {
        method: 'PUT',
        body
    });
export const clientPreferencesUpdate = async (preferences: any) =>
    apiFetch(clientPaths.preferences, {
        method: 'PUT',
        body: omit(preferences, ['client_preferences', 'clientRights', 'isClient', 'isTeamsApp'])
    });

export const fetchClient = (): Promise<Client> =>
    apiFetch(paths.client.root, {
        method: 'GET'
    }).then(({ created, password_reset, ...r }) => {
        return {
            created: new Date(created),
            password_reset:
                password_reset && /^0000/.test(password_reset)
                    ? password_reset
                    : new Date(password_reset),
            ...r
        };
    });

export const fetchAccount = () =>
    apiFetch(paths.client.account, {
        method: 'GET'
    });

export const asyncClientsFetch = (): Promise<Client[]> =>
    apiFetch(paths.client.multiple, {
        method: 'GET'
    }).then((r) =>
        r.map(({ created, password_reset, ...r }) => {
            return {
                created: new Date(created),
                password_reset:
                    password_reset && /^0000/.test(password_reset)
                        ? password_reset
                        : new Date(password_reset),
                ...r
            };
        })
    );
export const asyncFetchClientStorage = () => apiFetch(paths.client.storage);

export const asyncFetchClientUsersStorage = () => apiFetch(paths.client.usersStorage);

export const fetchClientStyles = () => apiFetch(paths.client.styles);

export const updateClientStyles = (styles: { primary: string }) =>
    apiFetch(paths.client.styles, {
        method: 'PUT',
        body: JSON.stringify(styles)
    });

export const asyncClientDomainsFetch = (): Promise<ClientDomain[]> =>
    apiFetch(clientPaths.domains, {
        method: 'GET'
    });

export const asyncClientDomainRefresh = (clientDomainUuid: string) =>
    apiFetch(clientPaths.domainSpecific, {
        method: 'PUT',
        params: { clientDomainUuid } satisfies ClientDomainSpecificSchema
    });

export const asyncClientDomainDelete = (clientDomainUuid: string) =>
    apiFetch(clientPaths.domainSpecific, {
        method: 'DELETE',
        params: { clientDomainUuid } satisfies ClientDomainSpecificSchema
    });

export const asyncClientDomainAdd = (name: string): Promise<ClientDomain> =>
    apiFetch(clientPaths.domains, {
        method: 'POST',
        body: { name } satisfies ClientDomainCreateBody
    });

export const clientUsersFetch = (): Promise<ClientUser[]> =>
    apiFetch(paths.client.users).then((r) =>
        r.map((g) => ({
            ...g,
            created: g.created && new Date(g.created),
            lastUsed: g.lastUsed && new Date(g.lastUsed),
            deleteRequested: g.deleteRequested && new Date(g.deleteRequested),
            expires: g.expires && new Date(g.expires)
        }))
    );

export const clientUserAdd = (payload) =>
    apiFetch(paths.client.user, {
        method: 'POST',
        body: JSON.stringify(payload)
    });

export const clientUserDelete = (userUuid: string, body: ClientMemberDeleteBody) =>
    apiFetch(paths.client.userSpecific, {
        method: 'DELETE',
        params: { userUuid } satisfies ClientMemberSpecificSchema,
        body
    });

export const clientUserEdit = (userUuid: string, body: ClientUserUpdateBody) =>
    apiFetch(paths.client.userSpecific, {
        method: 'PUT',
        params: { userUuid },
        body
    });

export const clientUserPasswordUpdate = (userUuid: string, password: string) =>
    apiFetch(paths.client.userSpecificPassword, {
        method: 'PUT',
        params: { userUuid } satisfies UserUuidSchema,
        body: { password } satisfies ClientUserPasswordUpdateBody
    });

export const clientUserTransfer = (userUuid, userDstUuid) => {
    return apiFetch(pathWithParams(paths.client.userTransfer, { userUuid, userDstUuid }), {
        method: 'PUT'
    });
};

export const clientGuestsFetch = () =>
    apiFetch(paths.client.guests).then((r) =>
        r.map((g) => ({
            ...g,
            created: g.created && new Date(g.created),
            lastUsed: g.lastUsed && new Date(g.lastUsed)
        }))
    );

export const asyncClientUserRemove = (userUuid: string) =>
    apiFetch(pathWithParams(paths.client.userSpecificRemove, { userUuid }), {
        method: 'DELETE'
    });

export const clientProjectsFetch = ({ count = 30, offset = 0 } = {}) =>
    apiFetch('/api/folders/list/projects/page', {
        method: 'POST',
        body: JSON.stringify({ pagination: { count, offset, order: 'desc' } })
    });

export const asyncClientCreate = (payload: ClientBody): Promise<Client> =>
    apiFetch(paths.client.root, {
        method: 'POST',
        body: payload
    });

export const asyncClientUpdate = (clientUuid: string, payload: ClientBodyUpdate): Promise<Client> =>
    apiFetch(paths.client.specific, {
        method: 'PUT',
        params: { clientUuid } satisfies ClientSpecificSchema,
        body: payload
    });

export const clientFetchTerms = (clientName, locale) =>
    apiFetch(pathWithParams(paths.client.terms, { clientName, locale }));

export const asyncClientTermsSet = (clientName, locale, terms) =>
    apiFetch(pathWithParams(paths.client.terms, { clientName, locale }), {
        method: 'PUT',
        body: JSON.stringify({
            terms
        })
    });

export const clientFetchStats = () => apiFetch(paths.client.stats);

export const clientFetchProjects = () =>
    apiFetch(paths.client.projects).then((projects) =>
        projects.map((p) => ({
            ...p,
            startDate: p.startDate ? new Date(p.startDate) : null,
            endDate: p.endDate ? new Date(p.endDate) : null
        }))
    );

export const clientFetchFinalFiles = (): Promise<any[]> => apiFetch(paths.client.finalFiles);

export const asyncClientOIDCLinkFetch = (): Promise<{
    label?: string | null;
    href: string;
} | null> => apiFetch(herawApiPaths.client.oidcLink);

export const asyncClientOIDCFetch = (): Promise<ClientOIDC | null> =>
    apiFetch(herawApiPaths.client.oidc);

export const asyncClientOIDCUpdate = (oidc: ClientOIDC) =>
    apiFetch(herawApiPaths.client.oidc, {
        method: 'PATCH',
        body: oidc
    });
