import React, { SyntheticEvent } from 'react';
import { FormField } from '../FormField/FormField';
import { FieldComponentProps } from 'react-modular-forms';

type TimeCodeProps = FieldComponentProps & { audio?: boolean };

export const TimeCode: React.FC<TimeCodeProps> = ({
    label,
    name,
    value,
    onChange,
    formId,
    audio
}) => (
    <FormField
        type="masked-field"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        mask={audio ? '__:__:__.___' : '__:__:__:__'}
        replacement={{ _: /\d/ }}
        formId={formId}
    />
);
