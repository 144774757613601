import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { asyncEmailVerify, asyncUserLogin } from '@he-novation/front-shared/async/user.async';
import { useTranslate } from '../../../../../hooks/useTranslate';

import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { fetchAccount, fetchUser } from '$redux/user/userActions';
import { userUuidSelector } from '$redux/user/userSelectors';

type FormNoteLoginProps = {
    formId: string;
};
export function FormNoteLogin({ formId }: FormNoteLoginProps) {
    const { userUuid } = useSelector(userUuidSelector);
    const [email, setEmail] = useState<string | undefined>();
    const [needsPassword, setNeedsPassword] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const dispatch = useDispatch();
    const { t } = useTranslate();

    useEffect(() => {
        if (email) {
            asyncEmailVerify(email)
                .catch(() => {
                    return { status: 'not_found' };
                })
                .then((r) => {
                    if (r.status === 'exists') setNeedsPassword(true);
                    else setNeedsPassword(false);
                });
        }
    }, [email]);

    useEffect(() => {
        if (email && userUuid) {
            setMessage(t('common.Successfully logged in'));
            setTimeout(() => {
                setMessage(null);
            }, 3_000);
        }
    }, [email, userUuid]);

    const onFocus = () => {
        dispatch(toggleKeyboardListeners(false));
    };
    if (userUuid && message) return <>{message}</>;
    if (userUuid) return null;
    return (
        <>
            <FormField
                formId={formId}
                type="recaptcha"
                name="recaptcha"
                siteKey={process.env.RECAPTCHA_FRONT_KEY}
                action={'verifyEmailOrPassword'}
            />
            <FormField
                className={formStyles.dark}
                formId={formId}
                id={`${formId}-email`}
                label={t('common.E-mail')}
                onFocus={onFocus}
                type={'email'}
                name="postedAs"
                value={email}
                onBlur={(e: SyntheticEvent<HTMLInputElement, Event>) => {
                    dispatch(toggleKeyboardListeners(true));
                    setEmail(e.currentTarget.value);
                }}
                validation={{
                    required: true
                }}
            />
            {email && needsPassword && (
                <FormField
                    className={formStyles.dark}
                    formId={formId}
                    label={t('common.Password')}
                    onFocus={onFocus}
                    onBlur={(e: SyntheticEvent<HTMLInputElement, Event>) => {
                        dispatch(toggleKeyboardListeners(true));
                        asyncUserLogin(email, e.currentTarget.value).then(() => {
                            dispatch(fetchAccount());
                            dispatch(fetchUser());
                        });
                    }}
                    type={'password'}
                    name="password"
                    validation={{
                        required: true
                    }}
                />
            )}
        </>
    );
}
