import styles from './SidePanelSubtitles.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VariableSizeList } from 'react-window';
import { SubtitleEntryBody } from '@he-novation/config/types/payloads/subtitle.payload';
import {
    MappedSubtitle,
    MappedSubtitleEntry,
    Subtitle,
    SubtitleEntry
} from '@he-novation/config/types/subtitle.types';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import {
    subtitleEntryCreate,
    subtitleEntryDelete,
    subtitleEntryUpdate
} from '@he-novation/front-shared/async/subtitle.async';
import { timeCodeToSeconds } from '@he-novation/lib-timecodes';
import { DELETE_CONFIRM } from '@he-novation/paths/modals.constants';
import __ from '@he-novation/utils/i18n';
import cn from 'classnames';
import update from 'immutability-helper';
import { useAtomValue } from 'jotai';
import { activeSubtitlesAtom, subtitlesAtom } from '../../../../atoms/file-atoms/subtitle-atoms';
import { videoAtom } from '../../../../atoms/file-atoms/video-atom';

import {
    FormSubtitle,
    FormSubtitleOutput
} from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/components/FormSubtitle';
import { SidePanelSubtitlesHeader } from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/SidePanelSubtitlesHeader';
import {
    SidePanelSubtitlesRow,
    SubtitleRow
} from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/SidePanelSubtitlesRow';
import { useTranslate } from '$hooks/useTranslate';
import { useVideoControls } from '$hooks/useVideoControls';
import { setSubtitlesTimeStamp } from '$redux/content/file/fileActions';
import { fileNameUuidAndVersionSelector } from '$redux/content/file/fileSelectors';
import { closeModal, openModal } from '$redux/route/routeActions';

const SUBTITLE_ROW_LAYOUT = {
    edition: {
        headerHeight: 32,
        buttonsHeight: 32
    },
    gap: 8,
    headerHeight: 32,
    lineHeight: 24,
    padding: {
        top: 16,
        bottom: 32
    },
    width: 360
};

export function SidePanelSubtitles() {
    const dispatch = useDispatch();

    const subtitles = useAtomValue(subtitlesAtom);
    const activeSubtitles = useAtomValue(activeSubtitlesAtom);
    const { fileUuid, fileVersion } = useSelector(fileNameUuidAndVersionSelector);

    const videoState = useAtomValue(videoAtom);

    const [activeSubtitle, setActiveSubtitle] = useState<MappedSubtitle>();
    const [editing, setEditing] = useState<string[]>([]);
    const [lastActiveEntryUuid, setLastActiveEntryUuid] = useState<string>();
    const [subtitleEntriesInPlayer, setSubtitleEntriesInPlayer] = useState<string[]>([]);
    const [formToggled, setFormToggled] = useState(false);

    const listRef = useRef<VariableSizeList<SubtitleRow>>(null);

    const { setCurrentTime } = useVideoControls();

    useEffect(() => {
        let active: Subtitle | undefined;
        for (const activeSubtitleUuid of activeSubtitles) {
            active = subtitles.find(
                (s) => s.uuid === activeSubtitleUuid && Array.isArray(s.entries)
            );
            if (active) break;
        }
        setActiveSubtitle(
            active ? mapSubs(active, videoState.frameRate, videoState.secondsOffset) : undefined
        );
        setEditing([]);
    }, [activeSubtitles, subtitles]);

    useEffect(() => {
        setSubtitleEntriesInPlayer(
            activeSubtitle
                ? getSubtitlesEntryUuidsInPlayer(activeSubtitle, videoState.currentTime)
                : []
        );
    }, [videoState.currentTime, activeSubtitle, videoState.secondsOffset]);

    useEffect(() => {
        if (subtitleEntriesInPlayer?.[0] && !editing?.length) {
            setLastActiveEntryUuid(subtitleEntriesInPlayer[0]);
        }
    }, [subtitleEntriesInPlayer]);

    useEffect(() => {
        if (activeSubtitle && lastActiveEntryUuid) {
            scrollTo(activeSubtitle.entries.findIndex((e) => e.uuid === lastActiveEntryUuid));
        }
    }, [editing, activeSubtitle, lastActiveEntryUuid]);

    const scrollTo = useCallback((index: number) => {
        if (listRef.current) listRef.current.scrollToItem(index, 'center');
    }, []);

    const appHeight = document.getElementById('main')?.offsetHeight || 0;
    const tabsHeaderHeight = document.getElementById('tabs-header')?.offsetHeight || 0;
    const subtitlesHeader =
        document.getElementById('side-panel-subtitles-header')?.offsetHeight || 0;

    const { t } = useTranslate();
    return (
        <div className={styles.subtitlesSidePanel}>
            <SidePanelSubtitlesHeader
                fileUuid={fileUuid}
                fileVersion={fileVersion}
                activeSubtitle={activeSubtitle}
                subtitles={subtitles}
                frameRate={videoState.frameRate}
                className={styles.header}
            />

            {activeSubtitle && (
                <>
                    <VariableSizeList
                        ref={listRef}
                        key={
                            'side-panel-subtitles-' + activeSubtitle.uuid + '-' + editing.join('-')
                        }
                        className={styles.list}
                        height={appHeight - tabsHeaderHeight - subtitlesHeader - 48}
                        width={SUBTITLE_ROW_LAYOUT.width}
                        itemCount={activeSubtitle.entries.length}
                        estimatedItemSize={
                            SUBTITLE_ROW_LAYOUT.padding.top +
                            SUBTITLE_ROW_LAYOUT.headerHeight +
                            SUBTITLE_ROW_LAYOUT.gap +
                            SUBTITLE_ROW_LAYOUT.lineHeight +
                            SUBTITLE_ROW_LAYOUT.padding.bottom
                        }
                        itemSize={(index: number) => {
                            const numberOfLines =
                                activeSubtitle.entries[index].content.split('<br/>').length;

                            let itemHeight =
                                SUBTITLE_ROW_LAYOUT.padding.top +
                                SUBTITLE_ROW_LAYOUT.headerHeight +
                                SUBTITLE_ROW_LAYOUT.gap;

                            if (editing.includes(activeSubtitle.entries[index].uuid)) {
                                itemHeight +=
                                    SUBTITLE_ROW_LAYOUT.gap +
                                    SUBTITLE_ROW_LAYOUT.edition.headerHeight +
                                    SUBTITLE_ROW_LAYOUT.gap +
                                    SUBTITLE_ROW_LAYOUT.edition.buttonsHeight +
                                    SUBTITLE_ROW_LAYOUT.gap;
                            }

                            itemHeight +=
                                SUBTITLE_ROW_LAYOUT.lineHeight * numberOfLines +
                                SUBTITLE_ROW_LAYOUT.padding.bottom;

                            return itemHeight;
                        }}
                        itemKey={(index) => activeSubtitle.entries[index].uuid}
                        itemData={{
                            subtitleEntries:
                                activeSubtitle?.entries.sort((a, b) => a.timeIn - b.timeIn) || [],
                            subtitleEntriesInPlayer,
                            editing: editing,
                            onClickDelete: (entryUuid: string) => {
                                dispatch(
                                    openModal(DELETE_CONFIRM, {
                                        onDelete: async () => {
                                            await subtitleEntryDelete(
                                                activeSubtitle.uuid,
                                                entryUuid
                                            );
                                            dispatch(closeModal());
                                            setActiveSubtitle(
                                                update(activeSubtitle, {
                                                    entries: {
                                                        $splice: [
                                                            [
                                                                activeSubtitle.entries.findIndex(
                                                                    (e) => e.uuid === entryUuid
                                                                ),
                                                                1
                                                            ]
                                                        ]
                                                    }
                                                })
                                            );
                                            dispatch(setSubtitlesTimeStamp());
                                        }
                                    })
                                );
                            },
                            onClickEdit: (entryUuid: string) => {
                                if (editing.includes(entryUuid)) {
                                    const newEditing = editing.filter((e) => e !== entryUuid);
                                    setEditing(newEditing);

                                    if (newEditing.length > 0) {
                                        setLastActiveEntryUuid(newEditing[newEditing.length - 1]);
                                    } else {
                                        setLastActiveEntryUuid(entryUuid);
                                    }
                                } else {
                                    setEditing([...editing, entryUuid]);
                                    setLastActiveEntryUuid(entryUuid);
                                }
                            },
                            saveChanges: async (data: FormSubtitleOutput) => {
                                const originalEntry = activeSubtitle.entries.find(
                                    (e) => e.uuid === data.uuid
                                )!;

                                const editedEntry = mapCreatedSubtitleEntry(
                                    { ...data, uuid: data.uuid! },
                                    videoState.frameRate
                                );

                                const _update = {
                                    content: editedEntry.content,
                                    timecodeIn: editedEntry.timecodeIn,
                                    timecodeOut: editedEntry.timecodeOut,
                                    metadata: {
                                        ...originalEntry.metadata,
                                        ...editedEntry.metadata
                                    }
                                };

                                await subtitleEntryUpdate(
                                    activeSubtitle.uuid,
                                    editedEntry.uuid,
                                    _update
                                );

                                const updated = update(activeSubtitle, {
                                    entries: {
                                        [activeSubtitle.entries.findIndex(
                                            (e) => e.uuid === editedEntry.uuid
                                        )]: { $merge: _update }
                                    }
                                });
                                updated.entries = updated.entries.sort(
                                    (a, b) => a.timeIn - b.timeIn
                                );
                                setActiveSubtitle(updated);

                                setEditing(editing.filter((uuid) => uuid !== editedEntry.uuid));
                                dispatch(setSubtitlesTimeStamp());
                            },
                            goToTime: (time: number) => {
                                setCurrentTime(time);
                            }
                        }}
                    >
                        {SidePanelSubtitlesRow}
                    </VariableSizeList>

                    {!formToggled && (
                        <Button
                            className={styles.addButton}
                            onClick={() => setFormToggled(!formToggled)}
                        >
                            {t('subtitles.Add new subtitle entry')}
                        </Button>
                    )}

                    {formToggled && (
                        <FormSubtitle
                            formId={'new-subtitle-form'}
                            visualIdentifier={<Icon icon={'subtitles'} />}
                            onSubmit={async (e, data) => {
                                const { uuid: createdSubtitleEntryUuid } =
                                    await subtitleEntryCreate(
                                        activeSubtitle.uuid,
                                        data as SubtitleEntryBody
                                    );

                                const updated = update(activeSubtitle, {
                                    entries: {
                                        $push: [
                                            mapCreatedSubtitleEntry(
                                                { ...data, uuid: createdSubtitleEntryUuid },
                                                videoState.frameRate
                                            )
                                        ]
                                    }
                                });
                                updated.entries = updated.entries.sort(
                                    (a, b) => a.timeIn - b.timeIn
                                );
                                setActiveSubtitle(updated);

                                const newIndex = updated.entries.findIndex(
                                    (e) => e.uuid === createdSubtitleEntryUuid
                                );
                                setTimeout(() => {
                                    setFormToggled(!formToggled);
                                    scrollTo(newIndex);
                                }, 100);
                            }}
                            onCancel={() => setFormToggled(!formToggled)}
                            className={cn(styles.addForm, formStyles.light)}
                        />
                    )}
                </>
            )}
        </div>
    );
}

function mapSubs(subtitle: Subtitle, frameRate: number, secondsOffset?: number): MappedSubtitle {
    return {
        ...subtitle,
        entries: subtitle.entries.map((e) => mapEntry(e, frameRate, secondsOffset))
    };
}

function mapEntry(e: SubtitleEntry, frameRate: number, secondsOffset = 0): MappedSubtitleEntry {
    return {
        ...e,
        timeIn: timeCodeToSeconds(e.timecodeIn, frameRate) - secondsOffset,
        timeOut: timeCodeToSeconds(e.timecodeOut, frameRate) - secondsOffset
    };
}

function mapCreatedSubtitleEntry(
    data: Required<FormSubtitleOutput>,
    frameRate: number
): MappedSubtitleEntry {
    const timeIn = timeCodeToSeconds(data.timecodeIn, frameRate);
    const timeOut = timeCodeToSeconds(data.timecodeOut, frameRate);

    return {
        ...data,
        start: timeIn,
        end: timeOut,
        timeIn,
        timeOut
    };
}

function getSubtitlesEntryUuidsInPlayer(
    activeSubtitle: MappedSubtitle,
    currentTime: number
): string[] {
    return activeSubtitle
        ? activeSubtitle.entries
              .filter((e) => e.timeIn <= currentTime && e.timeOut >= currentTime)
              .map(({ uuid }) => uuid)
        : [];
}
