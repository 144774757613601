import styles from './Tabs.module.scss';
import React, { ReactNode, SyntheticEvent, useState } from 'react';
import cn from 'classnames';
import { Button } from '../../buttons/Button/Button';
import { v4 as uuid } from 'uuid';
import { IconProp } from '../../graphics/Icon/Icon';

export enum TabsMode {
    Replace,
    Visibility,
    Display,
    ButtonsOnly
}

export type Tab = {
    value?: any;
    label: ReactNode;
    icon?: IconProp;
    onClick?: (e: SyntheticEvent, tab: Tab) => void;
};
export type TabsProps = {
    className?: string;
    tabs: Tab[];
    activeTab?: string;
    id?: string;
    mode?: TabsMode;
    children?: ReactNode | ReactNode[];
};

export const Tabs: React.FC<TabsProps> = ({
    id: _id,
    className,
    tabs,
    activeTab: _activeTab,
    mode = TabsMode.Replace,
    children
}) => {
    const [activeTab, setActiveTab] = useState<number>(
        _activeTab
            ? tabs.findIndex((t) => (!!t.value ? t.value === _activeTab : t.label === _activeTab))
            : 0
    );
    const [id] = useState(_id || uuid());
    const content = React.Children.toArray(children);

    const contentId = `${id}-tab-content`;

    let tabContent;
    if (mode === TabsMode.Replace) {
        if (content[activeTab]) {
            tabContent = (
                <div
                    className={cn(styles.content, 'tabs-content')}
                    id={`${contentId}-${activeTab}`}
                    aria-labelledby={`${id}-tab-${activeTab}`}
                >
                    {content[activeTab]}
                </div>
            );
        }
    } else if (mode !== TabsMode.ButtonsOnly) {
        tabContent = tabs.map((t, i) => (
            <div
                key={t.label?.toString() || t.value || i}
                className={cn(
                    styles.content,
                    'tabs-content',
                    activeTab !== i &&
                        (mode === TabsMode.Display ? styles.notDisplayed : styles.notVisible)
                )}
                id={`${contentId}-tab-${i}`}
                aria-labelledby={`${id}-${i}`}
            >
                {content[i]}
            </div>
        ));
    }

    return (
        <div className={cn(styles.tabs, className)} id={id}>
            <header id="tabs-header" className={styles.header}>
                <ul className={styles.buttons}>
                    {tabs.map((t, i) => {
                        const isActive = tabs[activeTab]?.value
                            ? tabs[activeTab].value === t.value
                            : tabs[activeTab]?.label === t.label;
                        return (
                            <li
                                key={t.label?.toString() || t.value || i}
                                className={isActive ? cn(styles.active, 'tab-active') : undefined}
                            >
                                <Button
                                    type="button"
                                    id={`${id}-tab-${i}`}
                                    className={styles.btn}
                                    role="tab"
                                    icon={t.icon}
                                    aria-controls={`${contentId}-${i}`}
                                    onClick={(e: SyntheticEvent) => {
                                        setActiveTab(i);
                                        t.onClick?.(e, t);
                                    }}
                                    aria-selected={isActive}
                                >
                                    {t.label}
                                </Button>
                            </li>
                        );
                    })}
                </ul>
            </header>
            {tabContent}
        </div>
    );
};
