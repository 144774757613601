import { useSelector } from 'react-redux';
import { AreaSelectionOutputData } from '@he-novation/config/types/area-selection.types';
import { FileEnum } from '@he-novation/config/types/file.types';
import { getThumbnail, ThumbnailGenerationData } from '@he-novation/front-shared/utils/media';

import {
    ELEMENT_ID_AUDIO_COMPARISON_VISUALIZER,
    ELEMENT_ID_AUDIO_VISUALIZER,
    ELEMENT_ID_PUBLIC_AUDIO_VISUALIZER,
    ELEMENT_ID_PUBLIC_VIDEO,
    ELEMENT_ID_VIDEO,
    ELEMENT_ID_VIDEO_COMPARISON
} from '$constants/constants.media';
import {
    activeAssetSelector,
    comparisonAssetSelector,
    fileHighlightedVersionSelector,
    fileTypeSelector,
    iframeCaptureSelector
} from '$redux/content/file/fileSelectors';

function fileTypeToId(
    fileType: FileEnum.Video | FileEnum.Audio,
    isPublicFile?: boolean,
    isComparison?: boolean
) {
    if (fileType === FileEnum.Video) {
        return isPublicFile
            ? ELEMENT_ID_PUBLIC_VIDEO
            : isComparison
            ? ELEMENT_ID_VIDEO_COMPARISON
            : ELEMENT_ID_VIDEO;
    }
    return isPublicFile
        ? ELEMENT_ID_PUBLIC_AUDIO_VISUALIZER
        : isComparison
        ? ELEMENT_ID_AUDIO_COMPARISON_VISUALIZER
        : ELEMENT_ID_AUDIO_VISUALIZER;
}

export function useGenerateThumbnail(isPublicFile?: boolean) {
    const { fileType } = useSelector(fileTypeSelector)!;
    const { iframeCapture } = useSelector(iframeCaptureSelector);
    const activeAsset = useSelector(activeAssetSelector)!;
    const comparisonAsset = useSelector(comparisonAssetSelector);
    const { fileHighlightedVersion } = useSelector(fileHighlightedVersionSelector)!;

    return async (metadata: AreaSelectionOutputData & { page?: number }) => {
        const asset =
            fileHighlightedVersion === activeAsset.version ? activeAsset : comparisonAsset;
        let thumbnailData: ThumbnailGenerationData | undefined;
        switch (fileType) {
            case FileEnum.HTML:
                thumbnailData = {
                    fileType: FileEnum.HTML,
                    iframeCapture
                };
                break;
            case FileEnum.Image:
                thumbnailData = {
                    fileType: FileEnum.Image,
                    src: asset!.url!
                };
                break;
            case FileEnum.PDF: {
                const src =
                    metadata.page !== undefined && asset!.metadata?.pagesSigned !== undefined
                        ? asset!.metadata?.pagesSigned[metadata.page]
                        : asset!.url;
                thumbnailData = {
                    fileType: FileEnum.Image,
                    src: src!
                };
                break;
            }
            case FileEnum.Video:
                thumbnailData = {
                    fileType: FileEnum.Video,
                    element: document.getElementById(
                        fileTypeToId(
                            fileType,
                            isPublicFile,
                            fileHighlightedVersion !== activeAsset.version
                        )
                    ) as HTMLVideoElement
                };
                break;
            case FileEnum.Audio:
                thumbnailData = {
                    fileType: FileEnum.Audio,
                    element: document.getElementById(
                        fileTypeToId(
                            fileType,
                            isPublicFile,
                            fileHighlightedVersion !== activeAsset.version
                        )
                    ) as HTMLCanvasElement
                };
                break;
        }

        let imageData: { min?: string; max?: string; detail?: string } | undefined;

        if (thumbnailData) {
            imageData = {
                min: await getThumbnail(thumbnailData, metadata, true),
                max: await getThumbnail(thumbnailData, metadata, false)
            };
        }
        if (iframeCapture) {
            if (!imageData) imageData = {};
            imageData.detail = iframeCapture;
        }
        return imageData;
    };
}
