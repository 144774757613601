export const i18n = {
    en: {
        'Bad file type': 'Bad file type',
        'Deselect all': 'Deselect all',
        'Drag and drop files or': 'Drag and drop files or',
        Less: 'Less',
        More: 'More',
        None: 'None',
        Search: 'Search',
        'Select all': 'Select all',
        'Select files': 'Select files',
        You: 'You'
    },
    de: {
        'Bad file type': 'Bad file type',
        'Deselect all': 'Deselect all',
        'Drag and drop files or': 'Drag and drop files or',
        Less: 'Less',
        More: 'More',
        None: 'None',
        Search: 'Search',
        'Select all': 'Select all',
        'Select files': 'Select files',
        You: 'You'
    },
    fr: {
        'Bad file type': 'Mauvais type de fichier',
        'Deselect all': 'Déselectionner tout',
        'Drag and drop files or': 'Glisser-déposer des fichiers ou',
        Less: 'Moins',
        More: 'Plus',
        None: 'Aucun résultat',
        Search: 'Recherche',
        'Select all': 'Sélectionner tout',
        'Select files': 'Sélectionner des fichiers',
        You: 'Vous'
    }
};
