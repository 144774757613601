import { CastCommon, CastContentChild } from '@he-novation/config/types/cast.types';
import {
    castFileLink,
    castFolderLink,
    castLink,
    projectCastLink
} from '@he-novation/paths/herawFrontUris';
import { FrontCast, FrontCastFolderChild } from '../types/cast.front-types';

export function mapCastContentChildToCast(
    item: CastContentChild<false>,
    {
        castUid,
        isPrivate,
        projectUuid
    }: {
        castUid: string;
        isPrivate?: boolean;
        projectUuid?: string;
    }
): FrontCastFolderChild {
    if (item.type === 'file') {
        let href: string | undefined;
        if (!isPrivate) {
            href = castFileLink(castUid, item.castFileUuid, item.version);
        }
        return {
            ...item,
            created: item.created ? new Date(item.created) : item.created,
            assets: item.assets,
            thumbnail: item.assets?.find((a) => a.type === 'thumbnail')?.url,
            href
        };
    }

    return {
        ...item,
        href: isPrivate
            ? projectUuid
                ? projectCastLink(projectUuid, castUid, item.uuid)
                : castLink(castUid, item.uuid)
            : castFolderLink(castUid, item.uuid)
    };
}

export function mapCastToFrontCast(
    cast: CastCommon,
    isPrivate?: boolean,
    projectUuid?: string
): FrontCast {
    return {
        ...cast,
        isPrivate: !!isPrivate,
        created: cast.created ? new Date(cast.created) : cast.created,
        expires: cast.expires ? new Date(cast.expires) : cast.expires,
        content: cast.content.map((item) =>
            mapCastContentChildToCast(item, { castUid: cast.uid, isPrivate, projectUuid })
        )
    };
}
