import styles from './Chips.module.css';
import React, { ReactNode, SyntheticEvent } from 'react';
import cn from 'classnames';

interface ChipsProps {
    onClick?: (e: SyntheticEvent<HTMLSpanElement, MouseEvent>) => void;
    onDelete?: () => void;
    children: ReactNode | ReactNode[];
    color?: string;
    background?: string;
    className?: string;
}

const Chips = ({ onDelete, children, onClick, color, background, className }: ChipsProps) => (
    <span
        className={cn(styles.chips, className)}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        style={{
            color,
            background
        }}
    >
        {children}
        {onDelete && (
            <button onClick={() => onDelete()} type="button">
                delete
            </button>
        )}
    </span>
);

export default Chips;
