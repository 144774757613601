import { useDispatch, useSelector } from 'react-redux';
import { AnyLogPayload } from '@he-novation/config/types/payloads/logs/log.payload';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { __ } from '@he-novation/design-system/utils/i18n';
import { folderLink, playerLink, projectFoldersLink } from '@he-novation/paths/herawFrontUris';
import { bytesToSize } from '@he-novation/utils/bytes';

import { useSocketIO } from '$hooks/useSocketIO';
import { useTranslate } from '$hooks/useTranslate';
import { openFeedbackModal, set } from '$redux/route/routeActions';
import { ACTIVITY_NEW_INCREMENT, openToast } from '$redux/ui/uiActions';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

const livenotify = async (
    setRoute,
    data: { livenotify?: { content: string; icon?: string }; log: AnyLogPayload },
    feedback
) => {
    if (data.livenotify) {
        if (Notification.permission === 'default') await Notification.requestPermission();
        if (Notification.permission === 'denied')
            return feedback(__('ERR_DESKTOP_NOTIFICATIONS'), 30000);

        const div = document.createElement('div');
        div.innerHTML = data.livenotify.content;

        const notificationNew = new Notification('HERAW', {
            icon: data.livenotify.icon,
            body: div.innerText
        });

        notificationNew.onclick = (e) => {
            e.preventDefault();

            notificationNew.close();

            if ('file' in data.log && data.log.file) {
                setRoute(playerLink(data.log.file.uuid, data.log.file.version));
                window.focus();
                return;
            }
            if ('folder' in data.log && data.log.folder) {
                setRoute(
                    data.log.project
                        ? projectFoldersLink(data.log.project.uuid, data.log.folder.uuid)
                        : folderLink(data.log.folder.uuid)
                );
                window.focus();
            }
        };
    }
};

export function useNotifications() {
    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const { t } = useTranslate();
    const dispatch = useDispatch();
    useSocketIO({
        room: currentUserUuid,
        socket: 'activity',
        actions: {
            sioActivityAdd: (data) => {
                if (currentUserUuid)
                    livenotify(
                        (route: string, silent?: boolean, replace?: boolean) =>
                            dispatch(set(route, silent, replace)),
                        data,
                        (msg: string, timeout?: number) => dispatch(openFeedbackModal(msg, timeout))
                    );

                if (data.request_user_uuid === currentUserUuid) {
                    return;
                }

                dispatch({
                    type: ACTIVITY_NEW_INCREMENT
                });
            }
        }
    });
    useSocketIO({
        room: currentUserUuid,
        socket: 'user',
        actions: {
            sioArchiveReady: (data) =>
                dispatch(
                    openToast({
                        title: __('ARCHIVE_READY'),
                        autoCloseAfterMs: 0,
                        content: `${data.name} (${bytesToSize(data.size)})`,
                        buttons: [
                            {
                                children: t('common.Download'),
                                tone: ButtonTone.Primary,
                                onClick: () => {
                                    window.open(data.downloadUrl, '_blank');
                                }
                            }
                        ]
                    })
                )
        }
    });
}
