import type { ComponentType } from 'react';
import React from 'react';
import { useAtom, WritableAtom } from 'jotai';

import { capitalizeFirstLetter } from '$helpers/capitalizeFirstLetter';

export function withJotaiAtom(atom: WritableAtom<any, any, any>, name: string) {
    return (Component: ComponentType) => {
        return function WithJotaiAtom(props: Record<string, unknown>) {
            const [state, setState] = useAtom(atom);
            return (
                <Component
                    {...{
                        ...props,
                        [name]: state,
                        ['set' + capitalizeFirstLetter(name)]: setState
                    }}
                />
            );
        };
    };
}
