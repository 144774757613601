import styles from './SidePanelGroups.module.css';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Title } from '@he-novation/design-system/components/text/Title/Title';
import { __ } from '@he-novation/design-system/utils/i18n';
import { contactGroupLink, contactsLink } from '@he-novation/paths/herawFrontUris';
import { ADD_GROUP, DELETE_CONFIRM, EDIT_GROUP } from '@he-novation/paths/modals.constants';
import { SidePanelList, SidePanelListItem } from '../../SidePanel/SidePanelList/SidePanelList';

import combineSelectors from '$helpers/combineSelectors';
import { useTranslate } from '$hooks/useTranslate';
import { deleteGroup } from '$redux/content/contacts/contactsActions';
import { groupsSelector } from '$redux/content/contacts/contactsSelectors';
import { closeModal, openModal, set } from '$redux/route/routeActions';
import { routeParamsSelector } from '$redux/route/routeSelectors';
import { hasClientRightSelector } from '$redux/user/userSelectors';

export default function SidePanelGroups() {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const {
        groups: { list },
        routeParams,
        hasClientRightContactAdmin
    } = useSelector(
        combineSelectors(
            groupsSelector,
            routeParamsSelector,
            hasClientRightSelector('contactAdmin')
        )
    );

    const addGroup = useCallback(() => dispatch(openModal(ADD_GROUP)), []);
    const removeGroup = useCallback(
        (uuid: string, routeParams: { route?: string; subroute?: string }) =>
            dispatch(
                openModal(DELETE_CONFIRM, {
                    onDelete: () => {
                        if (routeParams.route === 'contact-group' && routeParams.subroute === uuid)
                            dispatch(set(contactsLink()));

                        dispatch(deleteGroup(uuid));
                        dispatch(closeModal());
                    },
                    closeModal: () => dispatch(closeModal()),
                    message: t('common.Are you sure you want to delete this ?'),
                    title: t('common.Delete'),
                    cancel: t('common.cancel')
                })
            ),
        []
    );
    const editGroup = useCallback(
        (values: SidePanelListItem) =>
            dispatch(
                openModal(EDIT_GROUP, {
                    values
                })
            ),
        []
    );

    return (
        <div className={styles.goupsSidePanel}>
            <Title tag="h2" type="tab" className={styles.title} icon="users">
                {__('CONTACT_GROUPS')}
            </Title>

            <div className={styles.content}>
                <SidePanelList
                    className={styles.list}
                    items={list.map(({ name, count_contacts, uuid, isClientGroup }) => ({
                        name,
                        count: count_contacts,
                        href: contactGroupLink(uuid),
                        uuid,
                        isClientGroup,
                        className: isClientGroup ? 'is-client-group' : undefined
                    }))}
                    canEdit={({ isClientGroup }) => !isClientGroup || hasClientRightContactAdmin}
                    editItem={editGroup}
                    deleteItem={({ uuid }) => removeGroup(uuid, routeParams)}
                />

                <Button
                    className={styles.addButton}
                    icon={['user', 'plus']}
                    tone={ButtonTone.Outlined}
                    onClick={addGroup}
                >
                    {__('Add user groups')}
                </Button>
            </div>
        </div>
    );
}
