export type IconType = {
    set?: string;
    name: string;
    fill?: string;
    stroke?: string;
    width?: number;
    height?: number;
    circle?: boolean;
};

export const ICONS = [
    'arrow_reply',
    'arrow_up',
    'at',
    'bell',
    'briefcase',
    'brush',
    'building',
    'business_card',
    'calendar',
    'chart',
    'check',
    'check_encircled',
    'chevron',
    'clock',
    'cloud',
    'cog',
    'coins',
    'compare',
    'copy',
    'cut',
    'crown',
    'dashboard',
    'download',
    'enveloppe',
    'exit',
    'eye',
    'fast_forward',
    'file',
    'file_plain',
    'files',
    'filters',
    'flag',
    'folder',
    'folder_plain',
    'frame_forward',
    'fullscreen',
    'grid',
    'groupers',
    'home',
    'info',
    'key',
    'keyboard',
    'label',
    'link',
    'list',
    'lock',
    'magnifier',
    'minus',
    'msteams',
    'package',
    'panel',
    'paper_clip',
    'paper_plane',
    'pause',
    'pencil',
    'pictures',
    'pin',
    'play',
    'player_next',
    'plus',
    'plug',
    'question_mark',
    'refresh',
    'shape_cycle',
    'shapes',
    'signal',
    'slack',
    'speaker_on',
    'speech_bubble',
    'speech_bubbles',
    'square',
    'squares',
    'stack',
    'star',
    'stars',
    'stop_watch',
    'subtitles',
    'text_center',
    'text_left',
    'text_right',
    'trash',
    'three_dots',
    'upload',
    'user',
    'users',
    'world'
];

export const iconToString = (icon: IconType) => {
    let fileName = `${icon.set || 'default'}-${icon.name}`;
    let params: string[] = [];
    if (icon.stroke) params.push('s-' + icon.stroke.replace('#', 'hex'));
    if (icon.fill) params.push('f-' + icon.fill.replace('#', 'hex'));
    if (icon.width) params.push('w-' + icon.width.toString());
    if (icon.height) params.push('h-' + icon.height.toString());
    if (icon.circle) params.push('b-circle');
    return fileName + (params ? '--' + params.join('-') : '');
};
export const stringToIcons = (str: string): [IconType, ...IconType[]] => {
    const set = str.replace(/^([a-zA-Z0-9_]+)-.*/, '$1');
    let _str = str.replace(set + '-', '');
    const name = _str.replace(/^([a-zA-Z0-9_]+)(?:--.*|$)./, '$1');
    _str = _str.replace(name, '').replace(/^--/, '');
    const icon: IconType = { set, name };
    let [_optionsString, secondIcon] = _str.split('--');
    while (_optionsString.length) {
        const option = _optionsString.replace(/([a-z]+-[a-zA-Z0-9_]+).*/, '$1');
        const [o, v] = option.split('-');
        _optionsString = _optionsString.replace(option, '').replace(/^-/, '');
        if (o === 's') icon.stroke = v.replace('hex', '#');
        if (o === 'f') icon.fill = v.replace('hex', '#');
        if (o === 'w') icon.width = parseInt(v);
        if (o === 'h') icon.height = parseInt(v);
        if (o === 'b' && v === 'circle') icon.circle = true;
    }

    const icons: [IconType, ...IconType[]] = [icon];
    if (secondIcon) icons.push(stringToIcons(secondIcon)[0]);
    return icons;
};
