import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import {
    Direction,
    getElementAbsolutePosition
} from '@he-novation/design-system/utils/getAbsolutePosition';
import { useAtom } from 'jotai';
import { absoluteMenuAtom } from '../atoms/menus-atoms';

import { ABSOLUTE_MENU_ID } from '$components/AbsoluteMenu/AbsoluteMenu';

export function useAbsoluteMenu() {
    const [absoluteMenu, setAbsoluteMenu] = useAtom(absoluteMenuAtom);
    return {
        content: absoluteMenu.content,
        position: absoluteMenu.position,
        visible: absoluteMenu.visible,
        close() {
            setAbsoluteMenu((aM) => ({
                ...aM,
                content: null,
                visible: false
            }));
        },
        openAbsoluteMenu(
            content: ActionType[] | null,
            position: HTMLElement | [number, number],
            direction?: Direction
        ) {
            if (!content) {
                setAbsoluteMenu((aM) => ({
                    ...aM,
                    visible: false,
                    content
                }));
            }
            setAbsoluteMenu((aM) => ({
                ...aM,
                visible: false,
                content
            }));
            setTimeout(() => {
                let left: string | number;
                let top: string | number;
                if (position instanceof HTMLElement) {
                    const r = getElementAbsolutePosition(
                        position,
                        document.getElementById(ABSOLUTE_MENU_ID)!,
                        direction
                    );
                    left = r.left;
                    top = r.top;
                } else {
                    [left, top] = position;
                }
                setAbsoluteMenu({
                    position: [left, top],
                    visible: true,
                    content
                });
            }, 0);
        }
    };
}
