export const ADD_CONTACT = 'add-contact';
export const ADD_GROUP = 'add-group';
export const ATTACHMENT_NOTE = 'attachment-note';
export const CAST = 'cast';
export const CAST_FOLDER_TRIGGERS = 'cast-folder-triggers';
export const CAST_FORM = 'cast-form';
export const CAST_FORM_FOLDER = 'cast-form-folder';
export const CAST_SHARE = 'cast-share';
export const CLIENT = 'client';
export const CLIENT_DOMAIN = 'client-domain';
export const CLIENT_LABEL = 'client-event-label';
export const CLIENT_USER = 'client-user';
export const CLIENT_USER_PASSWORD = 'client-user-password';
export const COMPANY = 'company';
export const CONFIRM = 'confirm';
export const CONTACT_ADMIN = 'contact-admin';
export const DELETE_CONFIRM = 'delete-confirm';
export const DELETE_TEAM = 'delete-team';
export const EDIT_CONTACT = 'edit-contact';
export const EDIT_GROUP = 'edit-group';
export const EVENT = 'event';
export const FEEDBACK = 'feedback';
export const FILE_ADD = 'file-add';
export const FILE_INFO = 'file-info';
export const FILE_VERSIONS = 'file-versions';
export const FILE_VERSIONS_COMPARATOR = 'file-versions-comparator';
export const FOLDER_ADD = 'folder-add';
export const FOLDER_DOWNLOAD = 'folder-download';
export const FOLDER_MEMBERS = 'folder-members';
export const FOLDER_SHARE = 'folder-share';
export const FOLDER_TEAMS = 'folder-teams';
export const IMPORT_CONTACT = 'import-contact';
export const IMPORT_CONTACT_ERROR = 'import-contact-error';
export const ITEM = 'item';
export const ITEM_BOOKING_STATS = 'item-booking-stats';
export const ITEM_KIT = 'item-kit';
export const KEY_WORD = 'key-word';
export const KEY_WORD_NOTE = 'key-word-note';
export const PASSWORD_FORM = 'password';
export const PASSWORD_UPDATE_FORM = 'password-update';
export const PLUGIN_CONFIGURATION = 'plugin-config';
export const PROFILE_PICTURE = 'profile-picture';
export const PROJECT_FORM = 'project';
export const PROJECT_MEMBERS = 'project-members';
export const PROJECT_TEAMS = 'project-teams';
export const PUBLIC_FILE = 'public-file';
export const RECURSIVE_SETTINGS = 'recursive-settings';
export const RENAME_FILE = 'rename-file';
export const RENAME_FOLDER = 'rename-folder';
export const RENAME_TEAM = 'rename-team';
export const REVIEWERS_LIST = 'reviewers-list';
export const SERVER_ERROR = 'server-error';
export const SHOW_SHARED_FOLDERS = 'show-shared-folders';
export const SUBTITLES = 'subtitles';
export const SUBTITLES_BURN = 'subtitles-burn';
export const SUBTITLES_CREATE = 'subtitles-create';
export const SUBTITLES_GENERATE = 'subtitles-generate';
export const TASK_FORM = 'task';
export const TWO_FACTOR_AUTHENTICATION = 'two-factor-authentication';
export const UPLOAD_MANAGER = 'upload-manager';
export const USER_PROFILE_FORM = 'user-profile';
export const USER_TRANSFER = 'user-transfer';
export const VERSION_ADD = 'version-add';
export const VIEWS = 'views';
export const WAIT = 'wait';
